import React, { useState } from "react";

import {
  Container,
  DropDown,
  ArrowDropDown,
  ProfileAccount,
  ButtonLogout,
  IconLogout,
} from "./styles";
import { auth, Logout } from "../../services/auth";
import { useHistory } from "react-router-dom";

export default function UserSettings({}) {
  const history = useHistory();

  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <Container>
      <a
        onClick={() =>
          openDropdown ? setOpenDropdown(false) : setOpenDropdown(true)
        }
      >
        <img src={auth.currentUser.photoURL} style={{ width: 30 }} />
      </a>
      <DropDown opened={openDropdown}>
        <ArrowDropDown />
        <ProfileAccount>
          <img src={auth.currentUser.photoURL} />
          <h1>{auth.currentUser.displayName}</h1>
          <h3>{auth.currentUser.email}</h3>
        </ProfileAccount>

        <div className="divider" />
        <ButtonLogout
          onClick={() => {
            Logout().then(() => {
              history.push("/account/login");
            });
          }}
        >
          <h1>Sair</h1>
          <IconLogout style={{ height: 20, width: 20 }} />
        </ButtonLogout>
      </DropDown>
    </Container>
  );
}
