import { registerDownload } from "../../services/firestore";
import FileSaver from "file-saver";
import { createAlert } from "../../components/Alert";

const setTransform = (element, entries) => {
  const re = /(\w+)\(([^)]*)\)/g;
  let transform = {};
  let match;
  while ((match = re.exec(element.style.transform))) {
    transform[match[1]] = match[2];
  }
  transform = { ...transform, ...entries };
  element.style.transform = Object.entries(transform)
    .map(([key, value]) => `${key}(${value})`)
    .join(" ");
};

export const saveBlob = (blob, name) => {
  const image = document.createElement("img");
  var objectURL = URL.createObjectURL(blob);
  image.src = objectURL;
  image.onload = () => {
    if (image.width === image.height) {
      registerDownload(name, "squareLayouts");
    } else if (image.width < image.height) {
      registerDownload(name, "portraitLayouts");
    } else if (image.width > image.height) {
      registerDownload(name, "landscapeLayouts");
    }
  };
  FileSaver.saveAs(blob, "download.png");
};

export const shareBlob = (blob, name) => {
  var file = new File([blob], "download.png", { type: "image/png" });
  if (navigator.canShare && navigator.canShare({ files: [file] })) {
    navigator.share({
      files: [file],
      title: name,
    });
  }
};

export const resizeElement = (element, scale) => {
  setTransform(element, { scale });
};

export const renderImage = async (background, element, waterMark) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = element.naturalWidth;
    canvas.height = element.naturalHeight;
    const scale = element.naturalWidth / element.clientWidth;

    let { m41, m42 } = new DOMMatrix(
      window.getComputedStyle(background).transform
    );

    const backgroundWidth = background.getBoundingClientRect().width * scale;
    const backgroundHeight = background.getBoundingClientRect().height * scale;
    const backgroundLeft = (canvas.width - backgroundWidth) / 2;
    const backgroundTop = (canvas.height - backgroundHeight) / 2;

    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.drawImage(
      background,
      backgroundLeft + m41 * scale,
      backgroundTop + m42 * scale,
      backgroundWidth,
      backgroundHeight
    );
    context.drawImage(
      element,
      element.offsetLeft,
      element.offsetTop,
      element.naturalWidth,
      element.naturalHeight
    );

    // if (waterMark) {
    //   const waterMarkImg = new Image();
    //   waterMarkImg.onload = () => {
    //     const waterMarkWidth = canvas.width * 0.7;
    //     const waterMarkHeight =
    //       (waterMarkWidth / waterMarkImg.naturalWidth) *
    //       waterMarkImg.naturalHeight;
    //     context.globalAlpha = 0.6;
    //     context.drawImage(
    //       waterMarkImg,
    //       canvas.width * 0.15,
    //       (canvas.height - waterMarkHeight) / 2,
    //       waterMarkWidth,
    //       waterMarkHeight
    //     );
    //     canvas.toBlob((blob) => resolve(blob));
    //   };
    //   waterMarkImg.src = waterMark;
    // } else {
    //   canvas.toBlob((blob) => resolve(blob));
    // }
    canvas.toBlob((blob) => resolve(blob));
  });
};

export const dropPhoto = (event, setSelectedImage) => {
  const file = event.target.files[0];
  if (file.type.includes("image")) {
    var reader = new FileReader();
    reader.onloadend = function () {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
  } else {
    createAlert("error", "Apenas imagens são aceitas");
  }
};

export const moveElement = (element, xMove, yMove) => {
  function getTranslate(element) {
    var style = window.getComputedStyle(element);
    var matrix = new DOMMatrix(style.transform);
    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  }
  const { translateX, translateY } = getTranslate(element);

  setTransform(element, {
    translateX: parseInt(translateX + xMove) + "px",
    translateY: parseInt(translateY + yMove) + "px",
  });
};
