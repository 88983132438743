import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > h1 {
    text-align: center;
    font-weight: 700;
    font-size: 7.4rem;
    color: var(--primary);

    @media (max-width: 800px) {
      font-size: 6.4rem;
    }
  }
  > h2 {
    margin-top: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 2.4rem;
    color: var(--gray);
  }
`;
