import React, { useState } from "react";
import {
  Container,
  Content,
  ButtonAccess,
  Footer,
  Header,
  ButtonExplore,
  Logo,
  IconButtonAccess,
  IconArrow,
  ServiceExempleImage,
  DownloadHistory,
  DownloadHistoryTextContainer,
  ButtonsGroup,
} from "./styles";
import { Link, useHistory } from "react-router-dom";
import logoSouApoiador from "../../assets/images/logoSouApoiador.svg";
import serviceExemple from "../../assets/images/serviceExemple.svg";
import Button from "../../components/Button";
import { auth } from "../../services/auth";

export default function Home() {
  const history = useHistory();
  return (
    <>
      <Container>
        <Header>
          <Link to="/">
            <Logo src={logoSouApoiador} />
          </Link>
          <Link to="/account/dashboard">
            <h1>Meu painel</h1>
          </Link>
        </Header>
        <Content>
          <h1 className="title">Customize suas fotos</h1>
          <h2>Perfil / Stories</h2>
          <h4>
            Acesse, escolha seu layout, baixe e mostre para o mundo o que você
            apoia!
          </h4>
          <ButtonsGroup>
            <Button
              fullWidthMobile={true}
              onClick={() => history.push("/account/newcampaign")}
            >
              Faça já sua campanha
            </Button>
            <Button
              fullWidthMobile={true}
              type="outline"
              onClick={() => history.push("/souapoiador")}
            >
              Ver exemplo
            </Button>
          </ButtonsGroup>

          <DownloadHistory>
            <DownloadHistoryTextContainer>
              <h1>+17000 DOWNLOADS</h1>
              <h3>Pessoas estão mostrando seu apoio a todo momento!</h3>
            </DownloadHistoryTextContainer>
            <ServiceExempleImage src={serviceExemple} />
          </DownloadHistory>
        </Content>
        <Footer>
          <h1>
            ©2022 Todos os direitos reservados - Kera | Entre em{" "}
            <a
              onClick={() =>
                window.open("https://api.whatsapp.com/send?phone=5599985010495")
              }
            >
              Contato
            </a>
          </h1>
        </Footer>
      </Container>
    </>
  );
}
