import styled, { css, keyframes } from "styled-components";

const containerAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  margin: 12px 0;
  border-radius: 10px;
  padding: 40px 20px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 45px -2px rgba(0, 0, 0, 0.08);
  animation: ${containerAnimation} 0.3s ease-in-out;
  .titlePlan {
    font-size: 2.6rem;
    font-weight: 600;
    color: var(--black);
  }

  .descriptionPlan {
    color: var(--gray);
    font-size: 1.4rem;
    font-weight: 400;
  }
  .priceContent {
    padding: 20px 0;
  }
  .coin {
    font-size: 4.6rem;
    color: #cfcfcf;
  }
  .price {
    font-size: 4.6rem;
    color: var(--black);
    font-weight: 700;
  }
  .includesText {
    margin-top: 12px;
    margin-bottom: 8px;
    font-size: 1.4rem;
    color: var(--black);
    font-weight: bold;
  }

  .feature {
    margin: 12px 0;
    display: flex;
    align-items: center;
  }
  .featureTitle {
    font-size: 1.4rem;
    color: var(--gray);
    margin-left: 8px;
  }
`;
