import React from "react";
import Button from "../Button";
import { Check } from "react-feather";
import { Container } from "./styles";

export default function Plan({
  children,
  title,
  description,
  price,
  colorButton,
  textColorButton,
  onClick,
}) {
  return (
    <Container>
      <h1 className="titlePlan">{title}</h1>
      <p className="descriptionPlan">{description}</p>
      <div className="priceContent">
        <span className="coin">R$</span>
        <span className="price">{price}</span>
        <span className="priceDescription">/mês</span>
      </div>
      <Button
        fullWidth={true}
        style={{ backgroundColor: colorButton, color: textColorButton }}
        onClick={onClick}
      >
        Começar Agora
      </Button>
      <p className="includesText">Este plano inclui:</p>

      <div className="features">
        {children.map((feature) => (
          <div className="feature">
            <Check color="#178fd6" size={20} />
            <span className="featureTitle">{feature}</span>
          </div>
        ))}
      </div>
    </Container>
  );
}
