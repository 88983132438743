import styled, { keyframes, css } from "styled-components";
import CloseRounded from "@material-ui/icons/CloseRounded";
import DoneAllOutlined from "@material-ui/icons/DoneOutlined";
import ButtonBase from "@material-ui/core/ButtonBase";

export const Container = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  position: fixed;
  padding: 12px 0;
  z-index: 9999;
  background-color: var(--white);
  border-radius: 5px;
  align-items: center;
  justify-items: center;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.09);
  top: -75px;
  border-left: 5px solid
    ${(props) => (props.type == "error" ? css`var(--error)` : css`#1fdd0e`)};
  display: grid;
  grid-auto-columns: 10fr 80fr 10fr;
  grid-template-rows: 1fr;
  grid-template-areas: "First Mid Last";
  animation-fill-mode: forwards;
  @media (max-width: 800px) {
    width: 90vw;
    height: 65px;
  }
`;

export const IconClose = styled(CloseRounded)`
  grid-area: Last;
  && {
    color: var(--gray);
    font-size: 1.6rem;
    @media (max-width: 800px) {
      font-size: 1.8rem;
    }
  }
`;

export const IconError = styled(CloseRounded)`
  && {
    grid-area: First;
    margin: 0 8px;
    background-color: var(--error);
    border-radius: 99px;
    color: var(--white);
    font-size: 1.6rem;
    @media (max-width: 800px) {
      font-size: 2.4rem;
    }
  }
`;

export const ButtonClose = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const IconDone = styled(DoneAllOutlined)`
  grid-area: First;
  background-color: #1fdd0e;
  border-radius: 99px;
  color: var(--white);
  font-size: 1.6rem;
  @media (max-width: 800px) {
    font-size: 2.4rem;
  }
`;

export const Information = styled.div`
  justify-self: flex-start;
  grid-area: Mid;
  display: flex;
  height: 70%;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;

  > h1 {
    font-weight: bold;
    color: var(--gray);
    font-size: 1.4rem;
    @media (max-width: 800px) {
      font-size: 1.6rem;
    }
  }
  > span {
    font-weight: lighter;
    color: var(--gray);
    font-size: 1.2rem;
    @media (max-width: 800px) {
      font-size: 1.4rem;
    }
  }
  @media (max-width: 800px) {
    margin-left: 10px;
  }
`;
