import React, { useState, useEffect } from "react";
import {
  Container,
  Footer,
  Header,
  Logo,
  Content,
  Box,
  Boxes,
  DownloadIcon,
  SubTitle,
} from "./styles";
import { Link } from "react-router-dom";
import logoSouApoiadorHeader from "../../assets/images/logoSouApoiador2.svg";
import UserSettings from "../../components/UserSettings";
import { getCampaignByName } from "../../services/firestore";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";

export default function Analytics() {
  const { campaign } = useParams();
  const [loading, setLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState({
    total: 0,
    squareCount: 0,
    portraitCount: 0,
    landscapeCount: 0,
  });

  const calculatePercentage = (total, num) =>
    Math.round((100 / (total / num) || 0) * 100) / 100;

  useEffect(() => {
    getCampaignByName(campaign)
      .then((campaignData) => {
        const squareCount = campaignData.squareLayoutsCount || 0;
        const portraitCount = campaignData.portraitLayoutsCount || 0;
        const landscapeCount = campaignData.landscapeLayoutsCount || 0;
        setAnalyticsData({
          total: squareCount + portraitCount + landscapeCount,
          squareCount,
          portraitCount,
          landscapeCount,
        });
        setLoading(false);
      })
      .catch((err) => {
        setAnalyticsData(false);
      });
  }, [campaign]);

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Header>
          <Link to="/">
            <Logo src={logoSouApoiadorHeader} />
          </Link>
          <UserSettings />
        </Header>
        <Content>
          <h1>Analytics /{campaign}</h1>
          <h3>Downloads</h3>
          <Boxes>
            <Box type="total">
              <DownloadIcon />
              <h1 className="title">Total</h1>
              <h1 className="value">{analyticsData.total}</h1>
            </Box>
            <SubTitle>
              <h1>Modelos</h1>
              <div className="line" />
            </SubTitle>
            <Box type="profile">
              <h1 className="title">Perfil</h1>
              <h1 className="value">{analyticsData.squareCount}</h1>
              <h1 className="percentage">
                {calculatePercentage(
                  analyticsData.total,
                  analyticsData.squareCount
                )}
                %
              </h1>
            </Box>
            <Box type="portrait">
              <h1 className="title">Stories Vertical</h1>
              <h1 className="value">{analyticsData.portraitCount}</h1>
              <h1 className="percentage">
                {calculatePercentage(
                  analyticsData.total,
                  analyticsData.portraitCount
                )}
                %
              </h1>
            </Box>
            <Box type="landscape">
              <h1 className="title">Stories Horizontal</h1>
              <h1 className="value">{analyticsData.landscapeCount}</h1>
              <h1 className="percentage">
                {calculatePercentage(
                  analyticsData.total,
                  analyticsData.landscapeCount
                )}
                %
              </h1>
            </Box>
          </Boxes>
        </Content>

        <Footer>
          <h1>
            ©2022 Todos os direitos reservados - Kera | Entre em{" "}
            <a
              onClick={() =>
                window.open("https://api.whatsapp.com/send?phone=5599985010495")
              }
            >
              Contato
            </a>
          </h1>
        </Footer>
      </Container>
    </>
  );
}
