import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
:root {
  --primary: #178fd6;
  --secondary: #034488;
  --ternary: #F2CB05;
  --primaryChampaign:  #178fd6;
  --secondaryChampaign: #034488;
  --ternaryChampaign: #F2CB05;
  --error: #FF5555;
  --background: #fcfcfc;
  --white:  #fdffff;
  --gray: #6c6767;
  --gray10: #ECEBEB;
  --gray20: #cfcfcf;
  --yellow: #f2cb05;
  --yellow-hover:  #ffdd2e;
  --black:  #434343;
  --green:  #37B20C;
  
}

a:link {
  text-decoration: none;
}
html {
  font-size: 62.5%;
  @media (max-width: 800px) {
    font-size: 53%;
  }
}

html,
body,
#root {
}

body {
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

body,
input,
button,
text{
  font-family: 'Inter', sans-serif;
}

`;
