import styled, { css, keyframes } from "styled-components";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const containerAnimation = keyframes`
  from {
    background-color: rgba(255, 255, 255, 0);
  }

  to {
    background-color: rgba(47, 47, 47, 0.17)
  }
`;

export const Container = styled.div`
  background-color: rgba(47, 47, 47, 0.17);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  animation: ${containerAnimation} 0.3s ease-in-out;
`;

const contentAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Content = styled.div`
  padding: 20px 20px;
  position: relative;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 450px;
  max-height: 80vh;
  overflow-y: auto;
  animation: ${contentAnimation} 0.2s ease-in-out;
  ::-webkit-scrollbar {
    display: none;
  }
  background-color: var(--white);
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.09);
  border-radius: 14px;
  a {
    color: var(--primary);
    cursor: pointer;
  }
  > h1.title {
    font-weight: 700;
    font-size: 2.4rem;
    color: var(--black);
    text-align: left;
  }
  h1 {
    font-weight: 700;
    font-size: 2rem;
    color: var(--black);
    display: block;
    margin: 10px 0;
  }
  span {
    font-weight: normal;
    font-size: 1.4rem;
    color: var(--gray);
    width: 100%;

    line-height: 22px;
    @media (max-width: 800px) {
      text-align: center;
    }
  }
  > img {
    height: 150px;
  }

  @media (max-width: 800px) {
    min-width: 50px;
    width: 90%;
    align-items: center;
  }
`;

export const Image = styled.div`
  max-height: 200px;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 12px;
  > h1 {
    color: var(--black);
    font-size: 2rem;
    font-weight: 700;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 99px;
  width: 35px;
  height: 35px;
`;
export const CloseIcon = styled(CloseRoundedIcon)`
  && {
    color: var(--gray);
    font-size: 2.4rem;
  }
`;
