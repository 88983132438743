import React, { useState } from "react";
import {
  Content,
  SelectLayout,
  Layout,
  LoadingIcon,
  AddLayout,
  ImageInput,
  RemoveLayout,
  TrashIcon,
  ImageExemple,
} from "./styles";
import Button from "../Button";
import { createAlert } from "../../components/Alert";
import { imageURLToDataURL } from "../../utils/imageTools";
import portraitLayoutExemple from "../../assets/images/portraitLayoutExemple.svg";
import profileLayoutExemple from "../../assets/images/profileLayoutExemple.svg";
import landscapeLayoutExemple from "../../assets/images/landscapeLayoutExemple.svg";
import { Plus } from "react-feather";

export default function SelectLayouts({
  selectedLayout,
  layouts,
  type,
  isEditor,
}) {
  const [currentSelectedLayout, setCurrentSelectedLayout] = useState(null);
  const [loadedLayouts, setLoadedLayouts] = useState(null);
  const IsSelectedNull = () => {
    if (currentSelectedLayout == null) {
      createAlert("error", "Selecione um modelo");
    }
  };

  useState(() => {
    (async () => {
      setLoadedLayouts(
        await Promise.all(layouts.map((url) => imageURLToDataURL(url)))
      );
    })();
  }, [layouts]);

  return (
    <Content>
      <h1 className="title">{type.title} </h1>
      <h2>Selecione o modelo</h2>
      <SelectLayout>
        {loadedLayouts === null ? (
          <>
            <LoadingIcon
              type="spin"
              color={getComputedStyle(document.body).getPropertyValue(
                "--primaryChampaign"
              )}
              width={50}
              height={50}
            />
          </>
        ) : (
          <>
            {isEditor && (
              <>
                <AddLayout>
                  <ImageInput
                    type={"file"}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                  <Plus size={40} />
                  <span>Adicionar nova moldura</span>
                </AddLayout>

                {type.title == "Perfil" && loadedLayouts.length == 0 && (
                  <ImageExemple src={profileLayoutExemple} type={type.title} />
                )}
                {type.title == "Stories" && loadedLayouts.length == 0 && (
                  <ImageExemple src={portraitLayoutExemple} type={type.title} />
                )}
                {type.title == "Feed Vertical" && loadedLayouts.length == 0 && (
                  <ImageExemple
                    src={landscapeLayoutExemple}
                    type={type.title}
                  />
                )}
                {type.title == "Feed Horizontal" &&
                  loadedLayouts.length == 0 && (
                    <ImageExemple
                      src={landscapeLayoutExemple}
                      type={type.title}
                    />
                  )}
              </>
            )}
            {loadedLayouts
              ?.slice(0)
              .reverse()
              .map((layout, index) => (
                <Layout type={type.title} key={index}>
                  {/* <RemoveLayout>
                    <TrashIcon />
                  </RemoveLayout> */}
                  <input
                    onClick={(event) => setCurrentSelectedLayout(layout)}
                    type="radio"
                    name="layout"
                    value={layout}
                  />
                  <img src={layout} />
                </Layout>
              ))}
          </>
        )}
      </SelectLayout>
      <Button
        primary={true}
        onClick={() => (
          selectedLayout(currentSelectedLayout), IsSelectedNull()
        )}
      >
        Selecionar
      </Button>
    </Content>
  );
}
