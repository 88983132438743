import styled from "styled-components";

import ButtonBase from "@material-ui/core/ButtonBase";

export const GoogleLogin = styled(ButtonBase)`
  && {
    border: none;
    background-color: #fdfffd;
    border-radius: 999px;
    height: 50px;
    width: 300px;
    color: var(--gray);
    font-size: 1.6rem;
    font-weight: 200;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 50px 0 20px;
    justify-content: space-between;
    margin: 5px;
    -webkit-box-shadow: 0px 1px 3px 1px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 1px 3px 1px rgba(230, 230, 230, 1);
    box-shadow: 0px 1px 3px 1px rgba(230, 230, 230, 1);
    @media (max-width: 800px) {
      padding: 0 70px 0 20px;
    }
  }
`;
export const IconGoogleLogin = styled.img`
  width: 25px;
  height: 25px;
`;

export const FacebookLogin = styled(ButtonBase)`
  && {
    border: none;
    background-color: #415dae;
    border-radius: 999px;
    height: 50px;
    width: 300px;
    color: var(--white);
    font-size: 1.6rem;
    font-weight: 200;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 40px 0 20px;
    justify-content: space-between;
    margin: 5px;
    @media (max-width: 800px) {
      padding: 0 60px 0 20px;
    }
  }
`;
export const IconFacebookLogin = styled.img`
  width: 25px;
  height: 25px;
`;

export const AppleLogin = styled(ButtonBase)`
  && {
    border: none;
    background-color: #000;
    border-radius: 999px;
    height: 50px;
    width: 300px;
    color: var(--white);
    font-size: 1.6rem;
    font-weight: 200;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 55px 0 20px;
    justify-content: space-between;
    margin: 5px;
    @media (max-width: 800px) {
      padding: 0 75px 0 20px;
    }
  }
`;
export const IconAppleLogin = styled.img`
  width: 25px;
  height: 25px;
`;
