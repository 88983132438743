import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Footer,
  Header,
  BackgroundTop,
  Logo,
  Content,
  BoxLayout,
  LayoutsContent,
  AddIcon,
  CampaignInformationEditor,
  BoxAddLogo,
  BoxEditPalette,
  Plan,
  Palette,
  ImageInput,
  AddLayoutInput,
  FooterPopUp,
  FolderIconDrop,
  ContentAddLayout,
  ProgressBar,
  LoadingProgressBar,
  ContentProgressBar,
  LoadingProgressBarMain,
  IconPNG,
  IconDone,
  PalettesContent,
  PopUpEditPaletteContent,
  RemoveLogo,
  TrashIcon,
  ButtonInfo,
  InfoIcon,
  ImageExemples,
  ButtonHeader,
  ButtonUpgrade,
} from "./styles";

import exemples from "../../assets/images/exemples.svg";
import LayoutView from "../../components/LayoutView";
import PopUp from "../../components/PopUp";
import Button from "../../components/Button";
import { TwitterPicker } from "react-color";
import FirstLayout from "../../components/FirstLayout";
import logoSouApoiadorHeader from "../../assets/images/logoSouApoiador2.svg";
import { useParams } from "react-router-dom";
import filePng from "../../assets/images/filePNG.svg";
import Loading from "../../components/Loading";
import UserSettings from "../../components/UserSettings";
import {
  getCampaignByName,
  addLayoutToCampaign,
  removeLayoutFromCampaign,
  editCampaignPalette,
  editCampaignLogo,
  deleteCampaignData,
} from "../../services/firestore";
import { uploadLayout, deleteLayoutByURL } from "../../services/storage";
import { auth } from "../../services/auth";
import { useHistory } from "react-router-dom";
import { createAlert } from "../../components/Alert";

export default function Campaign() {
  const { campaign } = useParams();
  const history = useHistory();
  const [popUpDeleteChampaign, setPopUpDeleteChampaign] = useState(false);
  const [campaignData, setCampaignData] = useState({
    logo: null,
    palette: [],
    landscapeLayouts: [],
    portraitLayouts: [],
    squareLayouts: [],
    verticalFeed: [],
  });
  const [currentPaletteChange, setCurrentPaletteChange] = useState(0);
  const [loading, setLoading] = useState(true);
  const [popUpColorPiker, setPopUpColorPiker] = useState(false);
  const [popUpPro, setPopUpPro] = useState(false);
  const [popUpUpgradeToPRO, setPopUpUpgradeToPRO] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [popUpInstruction, setPopUpInstruction] = useState(false);
  const PopUpDeleteChampaign = () => {
    return (
      <PopUp
        title="Deletar Campanha"
        onClose={() => setPopUpDeleteChampaign(false)}
      >
        <span>
          Essa ação é irreversível e não haverá reembolso. Deseja realmente
          continuar?
        </span>
        <FooterPopUp>
          <Button danger={true} onClick={() => deleteCampaign(campaign)}>
            Deletar
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };

  const PlanProPopUp = () => {
    return (
      <PopUp onClose={() => setPopUpPro(false)} title="Campanha PRO">
        <span>
          Você será redirecionado ao nosso WhatsApp para conhecer nossas formas
          de pagamento.
        </span>
        <FooterPopUp>
          <Button
            primary="true"
            onClick={() => {
              window.$openpix.push([
                "pix",
                {
                  value: 2990,
                  correlationID: Math.random().toString(16).slice(2),
                  description: "Um mes de campanha PRO",
                  additionalInfo: [
                    {
                      key: "campaignName",
                      value: campaign,
                    },
                  ],
                },
              ]);
              const unsubscribe = window.$openpix.addEventListener((e) => {
                if (e.type === "PAYMENT_STATUS") {
                  if (e.data.status === "COMPLETED") {
                    setTimeout(() => {
                      history.push(`/account/${campaign}/editor`);
                    }, 2500);
                    unsubscribe();
                  }
                }
              });
            }}
          >
            Realizar Pagamento
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };

  const PopUpInstruction = () => {
    return (
      <PopUp onClose={() => setPopUpInstruction(false)}>
        <div>
          <ImageExemples src={exemples} />
        </div>
      </PopUp>
    );
  };
  const PopUpUpgradeToPro = ({ description }) => {
    return (
      <PopUp
        title="Recurso Indisponível"
        onClose={() => setPopUpUpgradeToPRO(false)}
      >
        <span style={{ width: "100%" }}>
          Para utilizar este recurso é necessário assinar o plano Profissional.
        </span>
        <FooterPopUp>
          <Button
            fullWidthMobile={true}
            onClick={() => {
              history.push("/pricing");
            }}
          >
            Assinar o plano PRO
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };
  const PopUpColorPiker = () => {
    return (
      <PopUp title="Alterar cor tema" onClose={() => setPopUpColorPiker(false)}>
        <PopUpEditPaletteContent>
          <PalettesContent>
            <Palette
              checked={currentPaletteChange == 0}
              color={campaignData.palette[0]}
              onClick={() => setCurrentPaletteChange(0)}
            />
            <Palette
              checked={currentPaletteChange == 1}
              color={campaignData.palette[1]}
              onClick={() => setCurrentPaletteChange(1)}
            />
            <Palette
              checked={currentPaletteChange == 2}
              color={campaignData.palette[2]}
              onClick={() => setCurrentPaletteChange(2)}
            />
          </PalettesContent>
          <TwitterPicker
            width={"95%"}
            triangle="hide"
            colors={[
              "#178FD6",
              "#034488",
              "#01A99C",
              "#01A451",
              "#8BC33F",
              "#F2CB05",
              "#F7921E",
              "#EF6421",
              "#EA1C24",
              "#922590",
              "#642C91",
              "#6C6767",
              "#343434",
            ]}
            color={campaignData.palette[currentPaletteChange]}
            onChange={(event) =>
              setCampaignData((data) => {
                data.palette[currentPaletteChange] = event.hex;
                return { ...data };
              })
            }
          />
        </PopUpEditPaletteContent>

        <FooterPopUp>
          <Button
            onClick={() => {
              editCampaignPalette(campaign, campaignData.palette).catch(() =>
                createAlert("error", "Erro ao editar campanha")
              );
              setPopUpColorPiker(false);
            }}
          >
            Alterar
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };

  const TaskStatus = ({ layoutName, imageType, layoutUpload }) => {
    const [progressUpload, setProgressUpload] = useState(0);

    useEffect(() => {
      layoutUpload.on("state_changed", (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgressUpload(progress);
      });
      layoutUpload.then(() => {
        setTimeout(
          () =>
            setTaskList((list) =>
              list.filter((task) => task.layoutName !== layoutName)
            ),
          1000
        );
      });
      layoutUpload.catch(() =>
        createAlert("error", "Erro no upload do arquivo: " + layoutName)
      );
    }, [layoutName, layoutUpload]);

    return (
      <ProgressBar>
        <IconDone progressUpload={progressUpload} />
        <IconPNG progressUpload={progressUpload} src={filePng} />
        <ContentProgressBar>
          <h1>{layoutName}</h1>
          <div className="category">{imageType}</div>
          <h3>{Math.round(progressUpload)}%</h3>
        </ContentProgressBar>
        <LoadingProgressBarMain>
          <LoadingProgressBar progress={progressUpload} />
        </LoadingProgressBarMain>
      </ProgressBar>
    );
  };

  const loadCampaignData = useCallback(async () => {
    try {
      setCampaignData(await getCampaignByName(campaign));
      setLoading(false);
    } catch (err) {
      createAlert("error", "Erro carregar dados da campanha");
      document.location.reload();
    }
  }, [campaign]);

  const deleteLayout = useCallback(
    async (layoutType, layoutURL) => {
      try {
        setCampaignData((data) => {
          data[layoutType] = data[layoutType].filter(
            (layout) => layout !== layoutURL
          );
          return { ...data };
        });
        await removeLayoutFromCampaign(campaign, layoutType, layoutURL);
        await deleteLayoutByURL(layoutURL);
      } catch (err) {
        createAlert("error", "Erro ao apagar o modelo");
        document.location.reload();
      }
    },
    [campaign]
  );

  const deleteCampaign = useCallback(
    async (campaignName) => {
      try {
        const campaignData = await getCampaignByName(campaignName);
        await Promise.all([
          ...campaignData.landscapeLayouts.map((url) => deleteLayoutByURL(url)),
          ...campaignData.portraitLayouts.map((url) => deleteLayoutByURL(url)),
          ...campaignData.squareLayouts.map((url) => deleteLayoutByURL(url)),
          campaignData.logo ? deleteLayoutByURL(campaignData.logo) : null,
          deleteCampaignData(campaignName),
        ]);
        history.push("/account/dashboard");
      } catch (err) {
        createAlert("error", "Erro ao excluir a campanha");
        document.location.reload();
      }
    },
    [history]
  );

  useEffect(() => {
    loadCampaignData();
  }, [loadCampaignData]);

  const addLayout = (event, layoutTag) => {
    for (const file of event.target.files) {
      const layoutCount = [
        ...(campaignData.squareLayouts ?? []),
        ...(campaignData.landscapeLayouts ?? []),
        ...(campaignData.portraitLayouts ?? []),
        ...(campaignData.verticalFeed ?? []),
      ].length;
      if (layoutCount >= 3 && campaignData.plan === "free") {
        createAlert(
          "error",
          "No plano gratuito só é possível adicionar 3 modelos"
        );
        return;
      }
      if (file.type === "image/png") {
        const reader = new FileReader();
        const tempImg = new Image();
        reader.onloadend = () => {
          tempImg.src = reader.result;
        };
        tempImg.onload = async () => {
          try {
            if (file.size / 1024 / 1024 > 2)
              throw new Error(
                "O tamanho do arquivo excede o limite máximo (2MB)"
              );
            const layoutUpload = uploadLayout(
              auth.currentUser.uid,
              tempImg.src
            );
            layoutUpload.then(async (res) => {
              await addLayoutToCampaign(
                campaign,
                layoutTag,
                await res.ref.getDownloadURL()
              );
              await loadCampaignData();
            });
            setTaskList((list) => [
              ...list,
              {
                layoutName: file.name,
                layoutTag,
                layoutUpload,
              },
            ]);
          } catch (err) {
            createAlert("error", err.message);
          }
        };
        reader.readAsDataURL(file);
      } else {
        createAlert("error", "Apenas imagens PNG são aceitas");
      }
    }
    document.getElementById("layout-input").value = null;
  };

  return (
    <>
      {loading && <Loading />}
      {popUpUpgradeToPRO && <PopUpUpgradeToPro />}
      <Container>
        <Header>
          <ButtonHeader onClick={() => history.push("/account/dashboard")}>
            Meu Painel
          </ButtonHeader>
          <div style={{ display: "flex" }}>
            <Button
              character={true}
              onClick={() => history.push("/" + campaign)}
              style={{ color: "white", marginRight: 12, fontSize: 14 }}
            >
              Visualizar campanha
            </Button>
            <UserSettings />
          </div>
        </Header>
        <BackgroundTop />
        <Content>
          {popUpInstruction && <PopUpInstruction />}
          {popUpDeleteChampaign && <PopUpDeleteChampaign />}
          {popUpColorPiker && <PopUpColorPiker />}
          {popUpPro && <PlanProPopUp />}
          <CampaignInformationEditor>
            <BoxAddLogo>
              {campaignData.logo != null ? (
                <>
                  <img
                    style={{ width: "80%", borderRadius: 5 }}
                    src={campaignData.logo}
                  />
                  <RemoveLogo
                    onClick={() => {
                      setCampaignData((data) => {
                        data.logo = null;
                        return { ...data };
                      });
                      editCampaignLogo(campaign, null).catch(() =>
                        createAlert("error", "Erro ao apagar logo")
                      );
                    }}
                  >
                    <TrashIcon />
                  </RemoveLogo>
                </>
              ) : (
                <>
                  <ImageInput
                    id="logo-input"
                    type="file"
                    onClick={(e) => {
                      if (campaignData.plan === "free") {
                        setPopUpUpgradeToPRO(true);
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      console.log(file);
                      if (file.type.includes("image")) {
                        const reader = new FileReader();
                        const tempImg = new Image();
                        reader.onloadend = () => {
                          tempImg.src = reader.result;
                        };
                        tempImg.onload = async () => {
                          try {
                            const layoutUpload = uploadLayout(
                              auth.currentUser.uid,
                              tempImg.src,
                              campaign
                            );
                            layoutUpload.then(async (res) => {
                              await editCampaignLogo(
                                campaign,
                                await res.ref.getDownloadURL()
                              );
                              await loadCampaignData();
                            });
                            setTaskList((list) => [
                              ...list,
                              {
                                layoutName: file.name,
                                imageType: "logo",
                                layoutUpload,
                              },
                            ]);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        reader.readAsDataURL(file);
                      } else {
                        createAlert("error", "Apenas imagens PNG são aceitas");
                      }
                      document.getElementById("logo-input").value = null;
                    }}
                  />
                  <AddIcon />
                  <span className="newChampaign">Adicionar Logo</span>
                </>
              )}
            </BoxAddLogo>
            <h1>/{campaign}</h1>
            {campaignData.plan === "pro" ? (
              <>
                {campaignData.published ? (
                  <Plan className="pro">
                    <h1>PRO</h1>
                  </Plan>
                ) : (
                  <ButtonUpgrade onClick={() => setPopUpPro(true)}>
                    Realizar Pagamento
                  </ButtonUpgrade>
                )}
              </>
            ) : (
              <ButtonUpgrade onClick={() => setPopUpPro(true)}>
                Upgrade
              </ButtonUpgrade>
            )}
            <BoxEditPalette>
              <div className="header">
                <span>Paleta de cores</span>
                <a
                  onClick={() =>
                    campaignData.plan === "free"
                      ? setPopUpUpgradeToPRO(true)
                      : setPopUpColorPiker(true)
                  }
                >
                  Editar
                </a>
              </div>
              <div className="palettes">
                <Palette color={campaignData.palette[0]} />
                <Palette color={campaignData.palette[1]} />
                <Palette color={campaignData.palette[2]} />
              </div>
            </BoxEditPalette>
          </CampaignInformationEditor>

          {taskList.map((task, index) => (
            <TaskStatus {...task} key={index} />
          ))}
          <h1 className="title">Suas Molduras</h1>
          <BoxLayout>
            <div className="header">
              <div className="header">
                <span>Modelo Perfil</span>
              </div>
              <Button className="buttonAdd" character={true}>
                <input
                  type={"file"}
                  multiple={true}
                  onChange={(e) => addLayout(e, "squareLayouts")}
                />
                Adicionar Modelo
              </Button>
            </div>

            {!campaignData.squareLayouts?.length && <FirstLayout />}
            <LayoutsContent>
              {campaignData.squareLayouts
                ?.slice(0)
                .reverse()
                .map((layout, index) => (
                  <LayoutView
                    loading={false}
                    layout={layout}
                    onRemove={() => deleteLayout("squareLayouts", layout)}
                    key={layout}
                  />
                ))}
            </LayoutsContent>
          </BoxLayout>
          <BoxLayout>
            <div className="header">
              <div className="header">
                <span>Stories</span>
              </div>
              <Button className="buttonAdd" character={true}>
                <input
                  type={"file"}
                  multiple={true}
                  onChange={(e) => addLayout(e, "portraitLayouts")}
                />
                Adicionar Modelo
              </Button>
            </div>
            {!campaignData.portraitLayouts?.length && <FirstLayout />}
            <LayoutsContent>
              {campaignData.portraitLayouts
                ?.slice(0)
                .reverse()
                .map((layout, index) => (
                  <LayoutView
                    onRemove={() => deleteLayout("portraitLayouts", layout)}
                    key={index}
                    layout={layout}
                  />
                ))}
            </LayoutsContent>
          </BoxLayout>
          <BoxLayout>
            <div className="header">
              <div className="header">
                <span>Feed Vertical</span>
              </div>
              <Button className="buttonAdd" character={true}>
                <input
                  type={"file"}
                  multiple={true}
                  onChange={(e) => addLayout(e, "verticalFeed")}
                />
                Adicionar Modelo
              </Button>
            </div>
            {!campaignData.verticalFeed?.length && <FirstLayout />}
            <LayoutsContent>
              {campaignData.verticalFeed
                ?.slice(0)
                .reverse()
                .map((layout, index) => (
                  <LayoutView
                    onRemove={() => deleteLayout("verticalFeed", layout)}
                    key={index}
                    layout={layout}
                  />
                ))}
            </LayoutsContent>
          </BoxLayout>
          <BoxLayout>
            <div className="header">
              <div className="header">
                <span>Feed Horizontal</span>
              </div>
              <Button className="buttonAdd" character={true}>
                <input
                  type={"file"}
                  multiple={true}
                  onChange={(e) => addLayout(e, "landscapeLayouts")}
                />
                Adicionar Modelo
              </Button>
            </div>
            {!campaignData.landscapeLayouts.length && <FirstLayout />}
            <LayoutsContent>
              {campaignData.landscapeLayouts
                .slice(0)
                .reverse()
                .map((layout, index) => (
                  <LayoutView
                    onRemove={() => deleteLayout("landscapeLayouts", layout)}
                    key={index}
                    layout={layout}
                  />
                ))}
            </LayoutsContent>
          </BoxLayout>
          <Button
            danger={true}
            onClick={() => setPopUpDeleteChampaign(true)}
            style={{ marginTop: 20 }}
          >
            Deletar Campanha
          </Button>
        </Content>

        <Footer>
          <h1>
            ©2022 Todos os direitos reservados - Kera | Entre em{" "}
            <a
              onClick={() =>
                window.open("https://api.whatsapp.com/send?phone=5599985010495")
              }
            >
              Contato
            </a>
          </h1>
        </Footer>
      </Container>
    </>
  );
}
