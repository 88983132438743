import React from "react";
import { ButtonComponent } from "./styles";

export default function Button({
  children,
  onClick,
  type,
  primary,
  danger,
  character,
  style,
  colorRelative,
  fullWidth,
  fullWidthMobile,
  disabled,
}) {
  return (
    <ButtonComponent
      disabled={disabled}
      colorRelative={colorRelative}
      fullWidth={fullWidth}
      fullWidthMobile={fullWidthMobile}
      style={style}
      onClick={onClick}
      type={type}
      primary={primary}
      danger={danger}
      character={character}
    >
      {children}
    </ButtonComponent>
  );
}
