import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  Information,
  IconClose,
  IconDone,
  ButtonClose,
  IconError,
} from "./styles";

let AlertElement = null;

export const createAlert = (type, message) => {
  if (AlertElement && AlertElement.getAttribute("is-show") === "false") {
    AlertElement.setAttribute("is-show", true);
    AlertElement.onshowalert(type, message);
  }
};

export const Alert = ({ children }) => {
  const [type, setType] = useState();
  const [message, setMessage] = useState();

  const AlertRef = useRef();

  useEffect(() => {
    AlertElement = AlertRef.current;
    AlertElement.setAttribute("is-show", false);
    AlertElement.onshowalert = (type, message) => {
      setType(type);
      setMessage(message);
      AlertElement.animate(
        { top: "10px" },
        {
          easing: "ease",
          fill: "forwards",
          duration: 500,
        }
      ).onfinish = () => {
        AlertElement.animate(
          { top: "-75px" },
          { fill: "forwards", easing: "ease", duration: 500, delay: 1700 }
        ).onfinish = () => AlertElement.setAttribute("is-show", false);
      };
    };
  }, []);

  return (
    <Container>
      <Box ref={AlertRef} type={type}>
        {type ? (
          <>
            <IconError />
            <Information>
              <h1>Error</h1>
              <span>{message ? message : "Falha na operação"}</span>
            </Information>
          </>
        ) : (
          <>
            <IconDone />
            <Information>
              <h1>Sucesso</h1>
              <span>
                {message ? message : "Sua operação foi realizada com sucesso!"}
              </span>
            </Information>
          </>
        )}
        <ButtonClose>
          <IconClose />
        </ButtonClose>
      </Box>
      {children}
    </Container>
  );
};
