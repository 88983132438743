import React from "react";
import {
  Container,
  Footer,
  Header,
  ButtonExplore,
  Logo,
  Content,
} from "./styles";
import { Link, useHistory } from "react-router-dom";
import logoSouApoiador from "../../assets/images/logoSouApoiador.svg";
import LoginButtonsGroup from "../../components/LoginButtonsGroup";

export default function Login() {
  const history = useHistory();

  return (
    <>
      <Container>
        <Header>
          <Link to="/">
            <Logo src={logoSouApoiador} />
          </Link>
        </Header>
        <Content>
          <h1>Faça seu login</h1>
          <h3>
            Para ter acesso aos recursos de nossa plataforma, faça o seu login:
          </h3>
          <LoginButtonsGroup
            onSuccess={() => history.push("/account/dashboard")}
          />
        </Content>
        <Footer>
          <h1>©2022 Todos os direitos reservados - Kera</h1>
        </Footer>
      </Container>
    </>
  );
}
