import React, { useEffect, useState, useRef } from "react";
import {
  Content,
  ButtonRemove,
  RemoveIcon,
  FooterPopUp,
  ContentPopUp,
} from "./styles";
import PopUp from "../PopUp";
import Button from "../Button";
import { imageURLToDataURL } from "../../utils/imageTools";
import { LoadingComponent } from "../../components/Loading";
export default function LayoutView({ layout, onRemove, loading }) {
  const [popUp, setPopUp] = useState(false);
  const [imageDataURL, setImageDataURL] = useState(null);

  useEffect(() => {
    imageURLToDataURL(layout)
      .then((res) => {
        document.getElementById(layout).onload = (element) => {
          element.target.parentElement.style.width =
            element.target.clientWidth + "px";
        };
        setImageDataURL(res);
      })
      .catch((err) => console.error(err));
  }, [layout]);

  const PopUpRemove = () => {
    return (
      <PopUp title="Remover Layout" onClose={() => setPopUp(false)}>
        <ContentPopUp>
          <span>Deseja realmente remover este layout da lista?</span>
          <img alt={layout} src={imageDataURL} />
        </ContentPopUp>
        <FooterPopUp>
          <Button
            danger="true"
            onClick={() => {
              onRemove();
              setPopUp(false);
            }}
          >
            Remover
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };

  return (
    <Content>
      {popUp && <PopUpRemove />}
      <ButtonRemove onClick={() => setPopUp(true)}>
        <RemoveIcon />
      </ButtonRemove>
      {loading ? (
        <LoadingComponent />
      ) : (
        <img
          id={layout}
          alt={imageDataURL ? layout : null}
          src={imageDataURL}
        />
      )}
    </Content>
  );
}
