import React from "react";
import {
  GoogleLogin,
  FacebookLogin,
  AppleLogin,
  IconGoogleLogin,
  IconFacebookLogin,
  IconAppleLogin,
} from "./styles";
import appleIcon from "../../assets/images/appleIcon.svg";
import googleIcon from "../../assets/images/googleIcon.svg";
import facebookIcon from "../../assets/images/facebookIcon.svg";
import {
  GooglePopupLogin,
  TwitterPopupLogin,
  FacebookPopupLogin,
} from "../../services/auth";

export default function LoginButtonsGroup({ onSuccess, onError }) {
  const onLoginSuccess = (result) => {
    //var token = result.credential.accessToken;
    //var user = result.user;
    console.log(result);
    if (onSuccess) onSuccess(result);
  };

  const onLoginFailed = (error) => {
    console.log(error);
    if (onError) onError(error);
  };

  return (
    <>
      <GoogleLogin
        onClick={() =>
          GooglePopupLogin().then(onLoginSuccess).catch(onLoginFailed)
        }
      >
        <IconGoogleLogin src={googleIcon} />
        Continuar com Google
      </GoogleLogin>
      {/* <FacebookLogin
          onClick={() =>
            FacebookPopupLogin()
              .then(function (result) {
                var token = result.credential.accessToken;
                var user = result.user;
                console.log(user, token);
              })
              .catch(function (error) {
                console.log(error);
              })
          }
        >
          <IconFacebookLogin src={facebookIcon} />
          Continuar com Facebook
        </FacebookLogin>
        <AppleLogin
          onClick={() =>
            TwitterPopupLogin()
              .then(function (result) {
                var token = result.credential.accessToken;
                var user = result.user;
                console.log(user, token);
              })
              .catch(function (error) {
                console.log(error);
              })
          }
        >
          <IconAppleLogin src={appleIcon} />
          Continuar com Apple
        </AppleLogin> */}
    </>
  );
}
