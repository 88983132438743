export const imageURLToDataURL = (url) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem(url)) {
      resolve(localStorage.getItem(url));
    } else {
      const loadImg = new Image();
      loadImg.crossOrigin = "";
      loadImg.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = loadImg.naturalWidth;
        canvas.height = loadImg.naturalHeight;
        ctx.drawImage(loadImg, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        do {
          try {
            localStorage.setItem(url, dataURL);
          } catch (err) {
            if (localStorage.length > 0) {
              localStorage.removeItem(localStorage.key(0));
            } else {
              break;
            }
          }
        } while (!localStorage.getItem(url));
        resolve(dataURL);
      };
      loadImg.onerror = (err) => {
        reject(new Error(err));
      };
      loadImg.src = url;
    }
  });
};
