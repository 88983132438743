import styled from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
export const Container = styled.div`
  width: 90vw;
  margin: 0 auto;
  grid-row-gap: 20px;
  align-items: center;
  display: grid;
  grid-template-rows: 80px auto 80px;
`;

export const Content = styled.div`
  max-width: 1280px;
  min-height: 70vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    max-width: 100vw;
  }
  > h1 {
    grid-area: title;
    text-align: center;
    font-weight: 700;
    font-size: 8.4rem;
    color: var(--primary);

    &.error {
      color: var(--error);
    }

    @media (max-width: 800px) {
      font-size: 7.4rem;
    }
  }
  > h3 {
    width: 320px;
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.8rem;
    color: var(--gray);
    line-height: 2.3rem;
    font-weight: normal;
  }
`;
export const Header = styled.div`
  display: flex;
  width: 90vw;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonExplore = styled(ButtonBase)`
  && {
    border: none;
    background-color: var(--primary);
    border-radius: 10px;
    padding: 18px 32px 18px 32px;
    color: var(--white);
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;

    @media (max-width: 800px) {
      padding: 16px 25px 16px 25px;
      font-size: 1.6rem;
    }
    :hover {
      background-color: var(--secondary);
    }
  }
`;
export const Logo = styled.img``;

export const Footer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    height: auto;
    color: var(--gray);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
  }
`;
