import styled, { keyframes, css } from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import FolderIcon from "@material-ui/icons/Folder";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import InfoRounded from "@material-ui/icons/InfoRounded";

const primary = "#178FD6";
const secondary = "#034488";
const tertiary = "#F2CB05";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  grid-row-gap: 20px;
  align-items: center;
  display: grid;
  background-color: #f9f7f9;
  grid-template-rows: 50px auto 80px;
  @media (max-width: 800px) {
    grid-template-rows: 60px auto 80px;
  }
  a {
    cursor: pointer;
  }
  h1.title {
    font-size: 1.8rem;
    color: var(--gray);
    margin-top: 25px;
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  z-index: 2;
  width: 60vw;
  padding-top: 50px;
  min-height: 70vh;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    padding-top: 20px;
    width: 100vw;
    align-items: center;
    justify-content: center;
  }
  > h1 {
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    color: var(--white);
    &.error {
      color: var(--error);
    }
  }

  > h3 {
    width: 320px;
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.8rem;
    color: var(--gray);
    line-height: 2.3rem;
    font-weight: normal;
  }
`;

export const BoxLayout = styled.div`
  width: 100%;
  display: grid;
  min-height: 250px;
  & + & {
    margin-top: 25px;
  }
  grid-gap: 10px;
  grid-auto-rows: 10px 1fr;
  padding: 20px 20px;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.3s;
  @media (max-width: 800px) {
    width: 80%;
  }
  > div.header {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: var(--gray);
      font-size: 1.4rem;
      font-weight: normal;
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
`;

export const LayoutsContent = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  align-items: center;
  align-self: flex-end;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  @media (max-width: 800px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 10px;
  }

  > div {
    margin-right: 20px;
    display: flex;
    justify-content: center;
  }
`;

export const Header = styled.div`
  top: 0;
  z-index: 999;
  height: 50px;
  display: flex;
  width: 100%;
  padding: 0 10vw;
  align-items: center;
  background-color: var(--primary);
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  @media (max-width: 800px) {
    height: 60px;
  }
`;

export const BackgroundTop = styled.div`
  background-color: var(--primary);
  width: 100%;
  min-height: 250px;
  position: absolute;
  z-index: 1;
  top: 0;
`;

export const Logo = styled.img`
  height: 30px;
`;

export const Footer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    height: auto;
    color: var(--gray);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
  }
  a {
    color: var(--primary);
    cursor: pointer;
  }
`;

export const BoxAddLogo = styled.div`
  grid-area: BoxAddLogo;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 210px;
  width: 232px;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: 0.3s;
  :hover {
    transition: 0.3s;
    background-color: #e1f4ff;
  }
  .newChampaign {
    color: var(--primary);
    font-weight: bold;
    font-size: 1.8rem;
  }
`;

export const LinkView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BoxEditPalette = styled.div`
  grid-area: BoxEditPalette;
  height: 150px;
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.3s;

  > div.header {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      color: var(--gray);
      font-size: 1.4rem;
      font-weight: normal;
    }
    > a {
      text-decoration: none;
      color: var(--primary);
      font-weight: bold;
      font-size: 1.6rem;
    }
  }

  > div.palettes {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 25px;
  }
`;

export const Palette = styled.div`
  border-radius: 999px;
  width: 50px;
  height: 50px;
  background-color: ${({ color }) => color};
  ${(props) =>
    props.checked &&
    css`
      box-shadow: 0px 0px 5px 1px var(--primary);
    `}
`;

export const AddIcon = styled(AddRoundedIcon)`
  && {
    margin: 0 auto;
    margin-bottom: 20px;
    display: block;
    color: var(--primary);
    font-size: 4.8rem;
  }
`;

export const Plan = styled.div`
  grid-area: Plan;
  width: 80px;
  height: 35px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-self: left;
  justify-content: center;
  border-radius: 999px;
  background-color: var(--yellow);
  padding: 0 10px;
  &.pro {
    background-color: var(--yellow);
  }
  &.free {
    background-color: var(--white);
    > h1 {
      color: var(--black);
    }
  }
  > h1 {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--white);
  }
  @media (max-width: 800px) {
    justify-self: center;
  }
`;

export const CampaignInformationEditor = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 20px;
  align-items: flex-end;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: 2fr 6fr;
  grid-template-areas:
    "BoxAddLogo nameChampaign Plan"
    "BoxAddLogo BoxEditPalette BoxEditPalette";

  @media (max-width: 800px) {
    justify-items: center;
    grid-gap: 20px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "nameChampaign nameChampaign"
      "Plan Plan"
      "BoxAddLogo BoxAddLogo"
      "BoxEditPalette BoxEditPalette";
  }
  > h1 {
    grid-area: nameChampaign;
    align-self: center;
    text-align: left;
    font-weight: 700;
    font-size: 4rem;
    color: var(--white);
    @media (max-width: 800px) {
      justify-self: center;
    }
  }
`;

export const ImageInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const AddLayoutInput = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4px;
  padding: 40px 10px;
  border: 2px dashed #dcdcdc;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
  cursor: pointer;
  > h1 {
    color: var(--gray);
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
    font-weight: normal;
    margin-bottom: 10px;
  }
  :hover {
    background-color: #edfaff;
    transition: 0.3s;
  }

  @media (max-width: 800px) {
    width: 120;
    height: 130px;
  }
  > span {
    font-weight: lighter;
    font-size: 1rem;
    color: var(--gray);
    line-height: 22px;
    text-align: center;

    @media (max-width: 800px) {
      line-height: 1.8rem;
    }
  }
`;

export const FooterPopUp = styled.div`
  align-items: flex-end;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
  }
`;

export const FolderIconDrop = styled(FolderIcon)`
  && {
    font-size: 5rem;
    color: var(--primary);
  }
`;

export const ContentAddLayout = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    width: 84%;
  }
`;

export const ExemplesLayouts = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Exemple = styled.div`
  align-items: center;
  width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  > h1 {
    font-size: 1.6rem;
    color: var(--primary);
    margin: 10px;
  }
  > img {
    height: 120px;
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  margin: 20px 0;
  padding: 5px 20px 0 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 10fr 90fr;
  @media (max-width: 800px) {
    grid-template-columns: 20fr 80fr;
  }
  grid-template-rows: 0.7fr 1fr;
  grid-template-areas:
    "LeftContent HeaderContent "
    "LeftContent BottomContent ";
`;

export const LoadingProgressBar = styled.div`
  width: ${(props) => props.progress}%;
  height: 10px;
  background-color: var(--primary);
  transition: 0.6s;
  border-radius: 999px;
`;

export const LoadingProgressBarMain = styled.div`
  grid-area: BottomContent;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 10px;
  background-color: #f2f2f2;
  border-radius: 999px;
`;

const popAnimation = keyframes`
 from{
  transform:scale(1,1);
 }
 50%{
  transform:scale(1.4,1.4);
  }
  to{
  transform:scale(1,1);
}
`;

export const IconPNG = styled.img`
  opacity: ${(props) => (props.progressUpload === 100 ? 0 : 100)};
  transition: 0.3s;
  height: 90%;
  margin-bottom: 8px;
  align-self: center;
  justify-self: center;
  grid-area: LeftContent;
`;

export const IconDone = styled(DoneIcon)`
  && {
    width: 60%;
    align-self: center;
    justify-self: center;
    grid-area: LeftContent;
    color: #1fdd0e;
    font-size: 2.8rem;
    ${({ progressUpload }) =>
      progressUpload === 100 &&
      css`
        animation: ${popAnimation} 0.3s linear forwards;
      `};
  }
`;

export const ContentProgressBar = styled.div`
  grid-area: HeaderContent;
  display: flex;
  align-items: center;

  > h1 {
    font-size: 1.2rem;
    margin-right: 10px;
    color: var(--gray);
    max-width: 300px;
    @media (max-width: 800px) {
    }
  }
  > div.category {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 20px;
    background-color: var(--primary);
    border-radius: 5px;
    color: var(--white);
    font-size: 1rem;
    font-weight: bold;
  }
  > h3 {
    font-weight: lighter;
    font-size: 1rem;
    align-self: center;
    flex-grow: 1;
    color: var(--gray);
    text-align: right;
    @media (max-width: 800px) {
      width: 50%;
    }
  }
`;

export const UserDataHeader = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  justify-content: center;
  @media (max-width: 800px) {
    position: unset;
  }
  > h1 {
    margin: 0 5px;
    font-size: 1.4rem;
    color: #fff;
  }
  img {
    border-radius: 999px;
  }
`;

export const DropDown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 10px 0%;
  background-color: var(--white);
  border-radius: 5px;
  transition: 0.1s;
  top: 40px;
  z-index: -1;
  opacity: 1;
  @media (max-width: 800px) {
    width: 90%;
    left: 5%;
    top: 60px;
  }

  div.divider {
    width: 90%;
    margin: 10px 0;
    height: 1px;
    background-color: #00000010;
  }
  ${(props) =>
    !props.opened &&
    css`
      top: 15px;
      opacity: 0;
    `}
`;

export const ProfileAccount = styled.div`
  display: grid;
  width: 90%;
  grid-template-columns: 20fr 80fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "img h1"
    "img h3";
  align-items: center;

  > h1 {
    grid-area: h1;
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--black);
  }
  > h3 {
    grid-area: h3;
    font-weight: normal;
    font-size: 1rem;
    color: var(--black);
  }
  > img {
    align-self: center;
    grid-area: img;
    height: 30px;
  }
`;

export const ArrowDropDown = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  background-color: var(--white);
  @media (max-width: 800px) {
    right: 9%;
  }
`;

export const ButtonLogout = styled(ButtonBase)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 5px;
    width: 90%;
    height: 40px;
    transition: 0.3s;
    :hover {
      background-color: #ff555530;
    }
  }
  > h1 {
    font-size: 1.4rem;
    font-weight: lighter;
    color: var(--error);
  }
`;
export const IconLogout = styled(ExitToAppRoundedIcon)`
  && {
    color: var(--error);
    font-size: 1.4rem;
    cursor: pointer;
  }
`;
export const PalettesContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 97%;
  margin-bottom: 20px;
`;

export const PopUpEditPaletteContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const RemoveLogo = styled(ButtonBase)`
  && {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    border-radius: 99px;
    background-color: var(--error);
  }
`;
export const TrashIcon = styled(DeleteRoundedIcon)`
  && {
    color: var(--white);
    font-size: 1.6rem;
  }
`;

export const ButtonInfo = styled(ButtonBase)`
  && {
    left: 10px;

    border-radius: 99px;
  }
`;

export const InfoIcon = styled(InfoRounded)`
  && {
    color: var(--yellow);
    font-size: 3rem;
  }
`;

export const ImageExemples = styled.img`
  width: 100%;
`;

export const ButtonHeader = styled(ButtonBase)`
  && {
    height: 35px;
    padding: 0 20px;
    background-color: var(--white);
    color: var(--primary);
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
  }
`;
export const ButtonUpgrade = styled(ButtonBase)`
  && {
    grid-area: Plan;
    align-self: center;
    justify-self: center;
    background-color: var(--yellow);
    border-radius: 5px;
    color: var(--white);
    font-weight: bold;
    padding: 10px 20px;
    @media (max-width: 800px) {
      width: 60%;
      padding: 15px 0;
    }
  }
`;
