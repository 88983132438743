import styled, { css } from "styled-components";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import ButtonBase from "@material-ui/core/ButtonBase";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import TimelineRoundedIcon from "@material-ui/icons/TimelineRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
  grid-row-gap: 20px;
  align-items: center;
  display: grid;
  grid-template-rows: 50px auto 80px;
  background-color: #f9f7f9;
  @media (max-width: 800px) {
    grid-template-rows: 60px auto 80px;
  }
`;

export const Content = styled.div`
  width: 60vw;
  padding-top: 50px;
  min-height: 70vh;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media (max-width: 800px) {
    width: 100vw;
    align-items: center;
    justify-content: center;
  }
  > h1 {
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    color: var(--white);
    &.error {
      color: var(--error);
    }
  }
  > h3 {
    width: 320px;
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.8rem;
    color: var(--gray);
    line-height: 2.3rem;
    font-weight: normal;
  }
`;

export const CampaignName = styled.h1`
  color: var(--gray);
  font-size: 1.8rem;
  width: 100%;
`;
export const Header = styled.div`
  top: 0;
  z-index: 999;
  height: 50px;
  display: flex;
  width: 100%;
  padding: 0 10vw;
  align-items: center;
  background-color: var(--primary);
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  @media (max-width: 800px) {
    height: 60px;
  }
`;

export const ButtonLogout = styled(ButtonBase)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 5px;
    width: 90%;
    height: 40px;
    transition: 0.3s;
    :hover {
      background-color: #ff555530;
    }
  }
  > h1 {
    font-size: 1.4rem;
    font-weight: lighter;
    color: var(--error);
  }
`;
export const IconLogout = styled(ExitToAppRoundedIcon)`
  && {
    color: var(--error);
    font-size: 1.4rem;
    cursor: pointer;
  }
`;

export const UserDataHeader = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  justify-content: center;
  @media (max-width: 800px) {
    position: unset;
  }
  > h1 {
    margin: 0 5px;
    font-size: 1.4rem;
    color: #fff;
  }
  img {
    border-radius: 999px;
  }
`;

export const DropDown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 10px 0%;
  background-color: var(--white);
  border-radius: 5px;
  transition: 0.1s;
  top: 40px;
  z-index: -1;
  opacity: 1;
  @media (max-width: 800px) {
    width: 90%;
    left: 5%;
    top: 60px;
  }

  div.divider {
    width: 90%;
    margin: 10px 0;
    height: 1px;
    background-color: #00000010;
  }
  ${(props) =>
    !props.opened &&
    css`
      top: 15px;
      opacity: 0;
    `}
`;

export const ProfileAccount = styled.div`
  display: grid;
  width: 90%;
  grid-template-columns: 20fr 80fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "img h1"
    "img h3";
  align-items: center;

  > h1 {
    grid-area: h1;
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--black);
  }
  > h3 {
    grid-area: h3;
    font-weight: normal;
    font-size: 1rem;
    color: var(--black);
  }
  > img {
    align-self: center;
    grid-area: img;
    height: 30px;
  }
`;

export const ArrowDropDown = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  background-color: var(--white);
  @media (max-width: 800px) {
    right: 9%;
  }
`;

export const ContentFirstCampaign = styled.div`
  max-width: 1280px;
  min-height: 70vh;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const LeftColumn = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  > h1 {
    margin-bottom: 10px;
    display: block;
    text-align: left;
    font-weight: 700;
    font-size: 2.6rem;
    color: var(--primary);
  }
  > h3 {
    text-align: left;
    font-size: 1.6rem;
    color: var(--gray);
    line-height: 2.4rem;
    font-weight: normal;
  }
  @media (max-width: 800px) {
    width: 80vw;
    margin-top: 30px;
    > h1 {
      width: 100%;
      text-align: center;
    }
    > h3 {
      text-align: center;
    }
  }
`;

export const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    height: 330px;
  }
  @media (max-width: 800px) {
    width: 80vw;
    > img {
      height: 70vw;
    }
  }
`;

export const OptionsPrimaryChampaign = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  a {
    color: var(--primary);
    font-weight: bold;
    font-size: 1.4rem;
    text-decoration: none;
    cursor: pointer;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 30px;
    width: 80vw;
    > a {
      margin-top: 20px;
    }
  }
`;

export const ButtonBox = styled.button`
  position: relative;
  height: 210px;
  width: 232px;
  padding: 10px 10px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  border: none;
  transition: 0.3s;
  background-color: ${(props) =>
    props.status ? css`var(--white)` : css`#DEDEDE`};
  .newChampaign {
    color: var(--primary);
    font-weight: bold;
    font-size: 1.8rem;
  }
`;

export const LinkView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const AddIcon = styled(AddRoundedIcon)`
  && {
    margin: 0 auto;
    margin-bottom: 20px;

    display: block;
    color: var(--primary);
    font-size: 4.8rem;
  }
`;

export const Champaigns = styled.div`
  display: grid;
  width: 100%;

  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 232px);
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const BackgroundTop = styled.div`
  background-color: var(--primary);
  width: 100%;
  min-height: 350px;
  position: absolute;
  z-index: 1;
  top: 0;
`;

export const Logo = styled.img`
  width: 80%;
  border-radius: 5px;
`;

export const Footer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    height: auto;
    color: var(--gray);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
  }
  a {
    color: var(--primary);
    cursor: pointer;
  }
`;

export const ButtonEdit = styled(ButtonBase)`
  && {
    position: absolute;
    top: 10px;
    right: 45px;
    width: 32px;
    height: 32px;
    background-color: var(--primary);
    border-radius: 99px;
    cursor: pointer;

    border: solid 2px var(--white);
  }
`;
export const IconEdit = styled(EditRoundedIcon)`
  && {
    color: var(--white);
    font-size: 2rem;
  }
`;
export const ButtonAnalytics = styled(ButtonBase)`
  && {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    background-color: var(--yellow);
    border-radius: 99px;
    border: solid 2px var(--white);
    cursor: pointer;
  }
`;
export const IconAnalytics = styled(TimelineRoundedIcon)`
  && {
    color: var(--white);
    font-size: 2rem;
  }
`;

export const ButtonPreview = styled(ButtonBase)`
  && {
    position: absolute;
    top: 10px;
    right: 80px;
    width: 32px;
    height: 32px;
    background-color: var(--secondary);
    border-radius: 99px;
    border: solid 2px var(--white);
    cursor: pointer;
  }
`;
export const IconPreview = styled(VisibilityRoundedIcon)`
  && {
    color: var(--white);
    font-size: 2rem;
  }
`;
export const Status = styled.div`
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 909px;
  border: 3px solid #fff;
  background-color: ${(props) => (props.status ? css`#1FDD0E` : css`#FF5555`)};
`;

export const ButtonUpgradePro = styled.button`
  border: none;
  background-color: var(--yellow);
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: 35px;
  padding: 0 20px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
`;
export const FooterPopUp = styled.div`
  align-items: flex-end;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Plan = styled.div`
  grid-area: Plan;
  position: absolute;
  display: flex;
  top: 14px;
  left: 10px;
  align-items: center;
  border-radius: 5px;
  padding: 6px 16px;
  &.pro {
    background-color: var(--yellow);
  }
  &.free {
    background-color: var(--primary);
    > h1 {
      color: var(--black);
    }
  }
  > h1 {
    font-weight: bold;
    font-size: 1rem;
    color: var(--white);
  }
  @media (max-width: 800px) {
    justify-self: center;
  }
`;
