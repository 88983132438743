import styled from "styled-components";
import PortraitIcon from "@material-ui/icons/Portrait";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import AmpStories from "@material-ui/icons/AmpStoriesOutlined";
import StayCurrentLandscapeIcon from "@material-ui/icons/StayCurrentLandscape";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    color: var(--primaryChampaign);
  }

  h4 {
    margin-top: 1rem;
    width: 370px;
    font-size: 1.6rem;
    color: var(--gray);
    text-align: center;
    line-height: 2.4rem;
    font-weight: normal;
    @media (max-width: 800px) {
      width: 80%;
    }
  }
`;

export const SelectLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  box-shadow: 0px 0px 45px -2px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 280px;
  height: 360px;
  padding: 20px;
  background-color: #fcfcfc;
  @media (max-width: 800px) {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 85vw;
    height: auto;
  }

  > h1 {
    color: var(--secondaryChampaign);
    font-weight: 700;
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: -26px;
    @media (max-width: 800px) {
      font-size: 1.8rem;
      width: 100%;
      text-align: left;
      padding-left: 10px;
      margin: 0;
    }
  }

  > h3 {
    color: var(--gray);
    font-size: 1.4em;
    line-height: 2.1rem;
    font-weight: normal;
    text-align: center;
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const LayoutExemple = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 8px;
  margin: 0 auto;
  border: 2px solid var(--secondaryChampaign);
`;
export const ButtonSelectLayout = styled(ButtonBase)`
  && {
    background-color: var(--primaryChampaign);
    color: var(--white);
    font-weight: bold;
    margin-bottom: 10px;
    padding: 14px 32px;
    border-radius: 10px;
    width: 100%;
    font-size: 1.6rem;
    @media (max-width: 800px) {
      display: none;
    }
  }
  &:hover {
    transform: scale(1.03);
    transition: 0.3s;
  }
`;

export const ArrowIcon = styled(ArrowForwardIcon)`
  && {
    color: var(--white);
    font-size: 2.6rem;
  }
`;

export const ButtonSelectLayoutMobile = styled(ButtonBase)`
  && {
    display: none;
    @media (max-width: 800px) {
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: var(--primaryChampaign);
      width: 40px;
      height: 40px;
      padding: 0 10px;
      border-radius: 59px;
    }
  }
`;

export const SelectLayoutsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const AvatarIcon = styled(PortraitIcon)`
  && {
    color: var(--secondaryChampaign);
    font-size: 4rem;
    @media (max-width: 800px) {
      font-size: 3rem;
    }
  }
`;
export const FeedIcon = styled(SmartphoneIcon)`
  && {
    color: var(--secondaryChampaign);
    font-size: 4rem;
    @media (max-width: 800px) {
      font-size: 3rem;
    }
  }
`;
export const LandscapeIcon = styled(StayCurrentLandscapeIcon)`
  && {
    color: var(--secondaryChampaign);
    font-size: 4rem;
    @media (max-width: 800px) {
      font-size: 3rem;
    }
  }
`;

export const StoriesIcon = styled(AmpStories)`
  && {
    color: var(--secondaryChampaign);
    font-size: 4rem;
    @media (max-width: 800px) {
      font-size: 3rem;
    }
  }
`;

export const AddLayout = styled.div`
  cursor: pointer;
  position: absolute;
  padding: 0 20px;
  width: 100%;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000005;
  border-radius: 20px;
  backdrop-filter: blur(7px);
  border: 2px solid var(--primaryChampaign);
  span {
    color: var(--secondary);
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    @media (max-width: 800px) {
      font-size: 1.4rem;
    }
  }

  svg {
    color: var(--secondary);
    @media (max-width: 800px) {
      height: 30px;
      width: 30px;
    }
  }
`;
