import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  max-width: 1280px;
  padding: 40px 0;
  width: 100%;
  padding: 20px 40px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  justify-items: center;
`;

export const FooterPopUp = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  @media (max-width: 800px) {
    flex-direction: column;
    & + & {
      background-color: transparent;
    }
  }
`;

export const ContentPopUpPlan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 10px 0px;
  > img {
    height: 150px;
    margin-bottom: 10px;
  }
  > span {
    & {
      text-align: center;
    }
  }
`;
export const Content = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  @media (max-width: 800px) {
    max-width: 100vw;
  }
  > h1 {
    grid-area: title;
    text-align: center;
    font-weight: 800;
    font-size: 8.4rem;
    color: var(--primary);

    &.error {
      color: var(--error);
    }

    @media (max-width: 800px) {
      font-size: 6.4rem;
    }
  }
  > h3 {
    width: 320px;
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.8rem;
    color: var(--gray);
    line-height: 2.3rem;
    font-weight: normal;
  }
`;

export const Form = styled.form`
  .inputContainer {
    margin: 10px 0;
    display: flex;
    width: 100%;
    column-gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
    span {
      display: none;
    }
    @media (max-width: 800px) {
      span {
        display: block;
      }
    }
  }
  label {
    color: var(--black);
    font-weight: bold;
    font-size: 1.8rem;

    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  input[type="text"] {
    display: block;
    border: none;
    background-color: #eaeff2;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding: 16px 20px;
    font-size: 2.4rem;
    color: var(--gray);
    ::placeholder {
      font-size: 1.8rem;
      color: rgba(118, 115, 115, 0.44);
    }
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  span {
    font-size: 1.4rem;
    color: var(--gray);
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const InputRadioContainer = styled.div`
  margin: 15px 0;
  align-items: center;
  cursor: pointer;
  display: flex;
  > input[type="radio"] {
    margin-right: 10px;
    width: 1.8rem;
    cursor: pointer;
    height: 1.8rem;
  }
  > label {
    font-size: 1.6rem;
    color: var(--black);
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img``;

export const Footer = styled.div`
  margin-top: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    height: auto;
    color: var(--gray);
    font-size: 1.4rem;
    text-align: center;
    font-style: normal;
    font-weight: normal;
  }
  a {
    color: var(--primary);
    cursor: pointer;
  }
`;

export const PlansContainer = styled.div`
  display: flex;
  align-items: stretch;
  column-gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const VerifyCampaignNameStatus = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 2.4rem;
    text-align: center;
    @media (max-width: 800px) {
      font-size: 1.6rem;
    }
  }
  span {
    color: var(--gray);
    font-weight: 500;
    font-size: 2.4rem;
  }

  .green {
    margin-top: 4px;
    color: var(--green);
    font-size: 2.8rem;
  }

  .red {
    margin-top: 4px;
    color: var(--error);
    font-size: 2.8rem;
  }
`;
