import React from "react";
import { ContentFirstLayout } from "./styles";
import Button from "../../components/Button";

export default function Profile() {
  return (
    <ContentFirstLayout>
      <span>Ainda nao há nada aqui, adicione seu primeiro modelo.</span>
    </ContentFirstLayout>
  );
}
