import styled, { css } from "styled-components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FolderIcon from "@material-ui/icons/Folder";
import ButtonBase from "@material-ui/core/ButtonBase";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
export const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  @media (max-width: 800px) {
    grid-template-rows: 10vh auto 6vh;
    margin-top: 20px;
  }
`;
export const Header = styled.div`
  position: fixed;
  top: 0;
  z-index: 999;
  height: 6vh;
  display: flex;
  width: 100%;
  padding: 0 10vw;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    height: 60px;
  }
`;
export const ButtonBack = styled(ButtonBase)`
  && {
    background-color: var(--white);
    padding: 6px;
    color: #000;
    border-radius: 100%;
  }
`;
export const ArrowIcon = styled(ArrowBackIcon)`
  && {
    font-size: 3rem;
  }
`;

export const Footer = styled.div`
  background-color: var(--primaryChampaign);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    color: var(--white);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
  }
`;

export const InformationPreview = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 20px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  color: var(--black);
  background-color: var(--yellow);
  z-index: 9999;
  > a {
    margin-left: 4px;
    color: var(--primary);
    font-weight: bold;
  }
`;

export const Logo = styled.img`
  height: 150px;
  margin-bottom: 20px;
  border-radius: 10px;
  @media (max-width: 800px) {
    height: auto;
    width: 90%;
  }
`;

export const DropLogo = styled.div`
  width: 400px;
  height: 160px;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
  border-radius: 10px;
  border: none;
  ${(props) =>
    props.isDragActive &&
    css`
      border: 2px solid var(--primaryChampaign);
    `}

  @media (max-width: 800px) {
    width: 80%;
  }
  span {
    color: var(--secondary);
    font-size: 1.6rem;
  }
`;

export const ImageInput = styled.input`
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
  opacity: 0;
`;

export const RemoveLogo = styled(ButtonBase)`
  && {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    border-radius: 99px;
    background-color: var(--error);
  }
`;
export const TrashIcon = styled(DeleteRoundedIcon)`
  && {
    color: var(--white);
    font-size: 1.6rem;
  }
`;

export const FooterConfigEditor = styled.div`
  width: 100%;
  padding: 12px 20px;
  background-color: var(--white);
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 5px 27px rgba(0, 0, 0, 0.06);
`;

export const FooterPopUp = styled.div`
  align-items: flex-end;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
  }
`;

export const PalettesContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 97%;
  margin-bottom: 20px;
`;

export const PopUpEditPaletteContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Palette = styled.div`
  border-radius: 999px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: ${({ color }) => color};
  ${(props) =>
    props.checked &&
    css`
      box-shadow: 0px 0px 5px 1px var(--primary);
    `}
`;
