import styled from "styled-components";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

export const Content = styled.div`
  position: relative;
  background-color: #f2f1f1;
  width: 200px;
  height: 200px;
  display: inline-block;
  align-items: center;

  flex: 0 0 auto;
  > img {
    max-height: 200px;
  }
`;

export const ButtonRemove = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  border-radius: 99px;
  width: 25px;
  height: 25px;
  background-color: var(--error);
`;

export const RemoveIcon = styled(DeleteRoundedIcon)`
  && {
    color: var(--white);
    font-size: 1.6rem;
  }
`;

export const FooterPopUp = styled.div`
  align-items: flex-end;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
  }
`;
export const ContentPopUp = styled.div`
  padding: 30px 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  span {
    text-align: center;
  }
  > img {
    margin: 10px 0;
    max-height: 150px;
    background-color: #f2f1f1;
  }
`;
