import React from "react";
import { Container } from "./styles";

export default function PageNotFound() {
  return (
    <Container>
      <h1>Página Não Encontrada</h1>
      <h2>Verifique se o link da página está correto</h2>
    </Container>
  );
}
