import React from "react";
import { useEffect } from "react";

export default function AdsGoogle() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: "inline-block",
        width: "100%",
        height: "120px",
      }}
      data-ad-client="ca-pub-2041801184921002"
      data-ad-slot="5176598341"
      data-ad-format="auto"
    ></ins>
  );
}
