import styled, { keyframes } from "styled-components";
import ReactLoading from "react-loading";
export const LoadingIcon = styled(ReactLoading)`
  margin: auto auto;
`;
const progress = keyframes`
    0% {
      width: 0%;
    }
    70% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  `;

const textFadeTop = keyframes`
    0% {
      bottom: 100%;
    }
    70% {
      bottom: 100%;
    }
    100% {
      bottom: 0%;
    }
  `;

const textFadeBot = keyframes`
    0% {
      top: 100%;
    }
    70% {
      top: 100%;
    }
    100% {
      top: 0%;
    }
  `;

export const Container = styled.div`
  position: fixed;
  z-index: 999999;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div`
  transform: scale(0.7);

  > h2 {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    color: var(--gray);
  }

  div {
    width: 310px;
    height: 85px;
  }
  div > p {
    font-size: 60px;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    position: absolute;
    font-family: sans-serif;
  }

  .box1 {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .box1 > p {
    will-change: transform, box-shadow, z-index;
    animation: ${textFadeBot} 3s forwards;
  }

  .box2 {
    margin: 0 auto;
    background: #77777730;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }

  .box2 > div {
    width: 50%;
    background: #178fd6;
    border-radius: 10px;
    position: absolute;
    color: white;
    text-align: center;
    will-change: transform, box-shadow, z-index;
    animation: ${progress} 3s forwards;
  }

  .box2 > p {
    color: white;
    text-align: center;
    will-change: transform, box-shadow, z-index;
    animation: ${textFadeTop} 3s forwards;
  }
`;
