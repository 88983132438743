import styled, { css } from "styled-components";
import PortraitIcon from "@material-ui/icons/Portrait";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import StayCurrentLandscapeIcon from "@material-ui/icons/StayCurrentLandscape";
import ButtonBase from "@material-ui/core/ButtonBase";
import ReactLoading from "react-loading";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

export const Content = styled.div`
  width: 100vw;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    color: var(--primaryChampaign);
  }

  > h2 {
    font-weight: bold;
    font-size: 2rem;
    color: var(--secondaryChampaign);
  }

  > h4 {
    margin-top: 1rem;
    width: 370px;
    font-size: 2rem;
    color: var(--gray);
    text-align: center;
    line-height: 2.4rem;
    font-weight: normal;
    @media (max-width: 800px) {
      width: 80%;
    }
  }
`;

export const SelectLayout = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: start;
  min-height: 260px;
  width: 90%;
  transition: 0.3s;
  margin: 20px 0;
  padding: 0 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  @media (max-width: 800px) {
    width: 100%;

    ::-webkit-scrollbar {
      display: none;
    }
  }
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 10px;
  }
`;

export const Layout = styled.button`
  border: none;

  align-items: center;
  position: relative;
  background-color: #f5f5f5;
  margin: 10px 5px;
  input[type="radio"] {
    -webkit-appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
  }
  input[type="radio"]:checked {
    box-shadow: inset 0px 0px 0px 5px var(--ternaryChampaign);
  }
  ${(props) =>
    (props.type === "Perfil" &&
      css`
        > img {
          height: 260px;
        }
      `) ||
    (props.type === "Stories" &&
      css`
        > img {
          height: 300px;
        }
      `) ||
    (props.type === "Feed Vertical" &&
      css`
        > img {
          height: 300px;
        }
      `) ||
    (props.type === "Feed Horizontal" &&
      css`
        > img {
          height: 260px;
        }
      `)}
`;

export const LoadingIcon = styled(ReactLoading)`
  margin: auto;
`;

export const ButtonSelectLayout = styled(ButtonBase)`
  && {
    background-color: var(--ternaryChampaign);
    color: #000103;
    height: 50px;
    padding: 0 60px;
    border-radius: 50px;
    font-size: 1.8rem;
  }
`;

export const AddLayout = styled.div`
  min-width: 200px;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  border: 2px solid var(--primaryChampaign);
  transition: 0.3s;
  padding: 0 20px;

  span {
    color: var(--primaryChampaign);
    font-size: 1.6rem;

    text-align: center;
  }

  svg {
    color: var(--primaryChampaign);
  }
`;

export const ImageInput = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
`;

export const RemoveLayout = styled(ButtonBase)`
  && {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 27px;
    height: 27px;
    border-radius: 99px;
    background-color: var(--error);
    z-index: 1;
  }
`;
export const TrashIcon = styled(DeleteRoundedIcon)`
  && {
    color: var(--white);
    font-size: 1.6rem;
  }
`;

export const ImageExemple = styled.img`
  opacity: 0.4;
  margin: 10px 5px;
  margin-left: 12px;
  ${(props) =>
    (props.type === "Perfil" &&
      css`
        height: 260px;
      `) ||
    (props.type === "Stories Vertical" &&
      css`
        height: 300px;
      `) ||
    (props.type === "Stories Horizontal" &&
      css`
        height: 260px;
      `)}
`;
