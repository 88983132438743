import React, { useState, useEffect } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Campaign from "./pages/Campaign";
import NewCampaign from "./pages/NewCampaign";
import Dashboard from "./pages/Dashboard";
import CampaignEditor from "./pages/CampaignEditor";
import PageNotFound from "./components/PageNotFound";
import Analytics from "./pages/Analytics";
import { auth } from "./services/auth";

export function Routes() {
  const [authState, setAuthState] = useState("loading");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("user: " + user.email);
        setAuthState("authenticated");
      } else {
        setAuthState("not authenticated");
      }
    });
  }, []);

  useEffect(() => {
    console.log(authState !== "loading");
  }, [authState]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/:name">
          <Campaign />
        </Route>
        <Route exact path="/account/login">
          <Login />
        </Route>
        {authState === "authenticated" && [
          <Route key="Dashboard" exact path="/account/dashboard">
            <Dashboard />
          </Route>,
          <Route key="CampaignEditor" exact path="/account/:campaign/editor">
            <CampaignEditor />
          </Route>,
          <Route key="Analytics" exact path="/account/:campaign/analytics">
            <Analytics />
          </Route>,
          <Route key="NewCampaign" exact path="/account/newcampaign">
            <NewCampaign />
          </Route>,
        ]}
        {authState === "not authenticated" && (
          <Route path="/account/*">
            <Redirect to="/account/login" />
          </Route>
        )}
        {authState !== "loading" && (
          <Route path="*">
            <PageNotFound />
          </Route>
        )}
      </Switch>
    </BrowserRouter>
  );
}
