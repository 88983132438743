import React from "react";
import { Content, Container, ButtonClose, CloseIcon, Header } from "./styles";

export default function PopUp({ children, onClose, title }) {
  return (
    <Container>
      <Content>
        <Header>
          <h1 className="title">{title}</h1>
          <ButtonClose onClick={() => onClose()}>
            <CloseIcon />
          </ButtonClose>
        </Header>
        {children}
      </Content>
    </Container>
  );
}
