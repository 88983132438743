import styled, { keyframes, css } from "styled-components";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import ButtonBase from "@material-ui/core/ButtonBase";

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  z-index: 999;
  position: relative;
  justify-content: center;
  @media (max-width: 800px) {
    position: unset;
  }
  > h1 {
    margin: 0 5px;
    font-size: 1.4rem;
    color: #fff;
  }
  img {
    border-radius: 999px;
  }
`;

export const DropDown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  cursor: default;
  padding: 10px 0%;
  background-color: var(--white);
  border-radius: 5px;
  transition: 0.1s;
  top: 40px;
  z-index: -1;
  opacity: 1;

  @media (max-width: 800px) {
    width: 95%;
    right: 8px;
    top: 60px;
  }

  div.divider {
    width: 90%;
    margin: 10px 0;
    height: 1px;
    background-color: #00000010;
  }
  ${(props) =>
    !props.opened &&
    css`
      pointer-events: none;
      top: 15px;
      opacity: 0;
    `}
`;

export const ProfileAccount = styled.div`
  display: grid;
  width: 90%;
  grid-template-columns: 20fr 80fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "img h1"
    "img h3";
  align-items: center;

  > h1 {
    grid-area: h1;
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--black);
  }
  > h3 {
    grid-area: h3;
    font-weight: normal;
    font-size: 1.4rem;
    color: var(--black);
  }
  > img {
    align-self: center;
    grid-area: img;
    height: 30px;
  }
  @media (max-width: 800px) {
    > h1 {
      font-size: 2rem;
    }
    > h3 {
      grid-area: h3;
      font-weight: normal;
      font-size: 1.6rem;
      color: var(--black);
    }
    > img {
      align-self: center;
      grid-area: img;
      height: 35px;
    }
  }
`;

export const ArrowDropDown = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  background-color: var(--white);
  @media (max-width: 800px) {
    right: 9%;
  }
`;

export const ButtonLogout = styled(ButtonBase)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 5px;
    width: 90%;
    height: 40px;
    transition: 0.3s;
    :hover {
      background-color: #ff555530;
    }
  }
  > h1 {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--error);
  }
`;
export const IconLogout = styled(ExitToAppRoundedIcon)`
  && {
    color: var(--error);
    font-size: 1.4rem;
    cursor: pointer;
  }
`;
