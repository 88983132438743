import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Footer,
  Header,
  ArrowIcon,
  ButtonBack,
  InformationPreview,
  Logo,
  DropLogo,
  ImageInput,
  RemoveLogo,
  PalettesContent,
  PopUpEditPaletteContent,
  Palette,
  FooterPopUp,
  TrashIcon,
  FooterConfigEditor,
} from "./styles";

import SelectLayouts from "../../components/SelectLayouts";
import SelectLayoutType from "../../components/SelectLayoutType";
import logoSouApoiador from "../../assets/images/logoSouApoiador.svg";
import PhotoEditor from "../../components/PhotoEditor";
import { getCampaignByName } from "../../services/firestore";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import PageNotFound from "../../components/PageNotFound";
import { auth } from "../../services/auth";
import { useLocation } from "react-router-dom";
import { Plus, Grid } from "react-feather";
import Button from "../../components/Button";
import { PaletteOutlined } from "@material-ui/icons";
import PopUp from "../../components/PopUp";
import { TwitterPicker } from "react-color";
import AdsGoogle from "../../components/AdsGoogle";
export default function Campaign() {
  const [campaignData, setCampaignData] = useState({
    logo: null,
    plan: "free",
    palette: ["red", "blue", "green"],
    landscapeLayouts: [],
    portraitLayouts: [],
    squareLayouts: [],
    published: false,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [editorCampaign, setEditorCampaign] = useState(false);
  const [selectedLayoutType, setSelectedLayoutType] = useState(null);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [popUpColorPiker, setPopUpColorPiker] = useState(false);
  const [popUpUpgradeToPRO, setPopUpUpgradeToPRO] = useState(false);
  const [enableTypes, setEnableTypes] = useState([]);
  const [campaignExist, setCampaignExist] = useState(null);

  const location = useLocation();
  const { name } = useParams();
  const history = useHistory();

  useEffect(() => {
    location.search === "?editor=true" && setEditorCampaign(true);
    getCampaignByName(name)
      .then(async (campaignData) => {
        try {
          if (!campaignData.published) {
            await new Promise((resolve, reject) => {
              auth.onAuthStateChanged((user) => {
                console.log(user.uid, campaignData.author);
                if (!user || user.uid !== campaignData.author) {
                  reject(new Error("not found"));
                } else {
                  resolve();
                }
              });
            });
          }
          document.documentElement.style.setProperty(
            "--primaryChampaign",
            campaignData.palette[0]
          );
          document.documentElement.style.setProperty(
            "--secondaryChampaign",
            campaignData.palette[1]
          );
          document.documentElement.style.setProperty(
            "--ternaryChampaign",
            campaignData.palette[2]
          );
          if (campaignData.squareLayouts.length)
            setEnableTypes((data) => {
              return data.concat("squareLayouts");
            });
          if (campaignData.portraitLayouts.length)
            setEnableTypes((data) => {
              return data.concat("portraitLayouts");
            });
          if (campaignData.landscapeLayouts.length)
            setEnableTypes((data) => {
              return data.concat("landscapeLayouts");
            });
          if (campaignData.verticalFeed.length)
            setEnableTypes((data) => {
              return data.concat("verticalFeed");
            });
          setCampaignData(campaignData);
          setCampaignExist(true);
        } catch (err) {
          console.log(err.message);
          setCampaignExist(false);
        }
      })
      .catch((err) => {
        setCampaignExist(false);
      });
  }, [name]);

  const PopUpUpgradeToPro = () => {
    return (
      <PopUp
        title="Recurso Indisponível"
        onClose={() => setPopUpUpgradeToPRO(false)}
      >
        <span style={{ width: "100%" }}>
          Para utilizar este recurso é necessário assinar o plano Profissional.
        </span>
        <FooterPopUp>
          <Button
            fullWidthMobile={true}
            onClick={() => {
              history.push("/pricing");
            }}
          >
            Assinar o plano PRO
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };

  const PopUpColorPiker = () => {
    const [currentPaletteChange, setCurrentPaletteChange] = useState(0);
    return (
      <PopUp
        title="Alterar cor do tema"
        onClose={() => setPopUpColorPiker(false)}
      >
        <PopUpEditPaletteContent>
          <PalettesContent>
            <Palette
              checked={currentPaletteChange == 0}
              color={campaignData.palette[0]}
              onClick={() => setCurrentPaletteChange(0)}
            />
            <Palette
              checked={currentPaletteChange == 1}
              color={campaignData.palette[1]}
              onClick={() => setCurrentPaletteChange(1)}
            />
            <Palette
              checked={currentPaletteChange == 2}
              color={campaignData.palette[2]}
              onClick={() => setCurrentPaletteChange(2)}
            />
          </PalettesContent>
          <TwitterPicker
            width={"95%"}
            triangle="hide"
            colors={[
              "#178FD6",
              "#034488",
              "#01A99C",
              "#01A451",
              "#8BC33F",
              "#F2CB05",
              "#F7921E",
              "#EF6421",
              "#EA1C24",
              "#922590",
              "#642C91",
              "#6C6767",
              "#343434",
            ]}
            color={campaignData.palette[currentPaletteChange]}
            onChange={() => null}
          />
        </PopUpEditPaletteContent>

        <FooterPopUp>
          <Button fullWidthMobile={true} onClick={() => null} primary={true}>
            Alterar
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };

  return (
    <>
      {campaignExist === null && <Loading />}
      {campaignExist === false && <PageNotFound />}
      {popUpColorPiker && <PopUpColorPiker />}
      {popUpUpgradeToPRO && <PopUpUpgradeToPro />}
      {campaignExist === true && (
        <Container>
          <Header>
            {selectedLayoutType ? (
              <>
                <ButtonBack
                  onClick={() => {
                    if (selectedLayout) return setSelectedLayout(null);
                    if (selectedLayoutType) return setSelectedLayoutType(null);
                    if (selectedImage) return setSelectedImage(null);
                  }}
                >
                  <ArrowIcon />
                </ButtonBack>
              </>
            ) : (
              <div></div>
            )}
            {/* <div style={{ display: "flex" }}>
              <Button
                character={true}
                onClick={() => history.push("/account/dashboard")}
                style={{ color: "var(--gray)" }}
              >
                Campanhas
              </Button>
              <Button
                character={true}
                style={{ color: "var(--gray)" }}
                onClick={() => {
                  campaignData.plan == "free"
                    ? setPopUpUpgradeToPRO(true)
                    : setPopUpColorPiker(true);
                }}
              >
                <PaletteOutlined style={{ width: 20, height: 20 }} />
              </Button>
            </div> */}
          </Header>
          {campaignData.plan === "free" && <AdsGoogle />}
          {editorCampaign ? (
            <DropLogo isDragActive={campaignData.logo == null}>
              {campaignData.logo ? (
                <>
                  <Logo src={campaignData.logo} />
                  <RemoveLogo>
                    <TrashIcon />
                  </RemoveLogo>
                </>
              ) : (
                <>
                  <ImageInput
                    type={"file"}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                  <Plus size={30} />
                  <span>Adicionar logo</span>
                </>
              )}
            </DropLogo>
          ) : (
            campaignData.logo != null && <Logo src={campaignData.logo} />
          )}

          {selectedLayout ? (
            <PhotoEditor
              // isEditor={editorCampaign}
              isFree={campaignData.plan === "free"}
              selectedLayout={selectedLayout}
              selectedImage={selectedImage}
              resetAll={() => {
                setSelectedImage(null);
                setSelectedLayout(null);
                setSelectedLayoutType(null);
              }}
            />
          ) : (
            <>
              {selectedLayoutType ? (
                <SelectLayouts
                  isEditor={editorCampaign}
                  type={selectedLayoutType}
                  layouts={campaignData[selectedLayoutType.type]}
                  selectedLayout={(event) => setSelectedLayout(event)}
                />
              ) : (
                <SelectLayoutType
                  isEditor={editorCampaign}
                  enableTypes={enableTypes}
                  currentLayoutTypeSelected={(event) =>
                    setSelectedLayoutType(event)
                  }
                />
              )}
            </>
          )}
          {campaignData.plan === "free" && <AdsGoogle />}
          {editorCampaign && (
            <FooterConfigEditor>
              <Button primary={true} disabled={false}>
                <span>Salvar Alterações</span>
              </Button>
            </FooterConfigEditor>
          )}
        </Container>
      )}
    </>
  );
}
