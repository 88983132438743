import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes";
import GlobalStyles from "./styles.js";
import { Alert } from "./components/Alert";

function App() {
  return (
    <Alert>
      <Routes />
      <GlobalStyles />
    </Alert>
  );
}

export default App;
