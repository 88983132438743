import styled from "styled-components";
import { KeyboardArrowRight } from "@material-ui/icons";
import ButtonBase from "@material-ui/core/ButtonBase";
export const Container = styled.div`
  width: 90vw;
  margin: 0 auto;
  grid-row-gap: 90px;
  align-items: center;
  display: grid;
  grid-template-rows: 80px auto 80px;
`;
export const Header = styled.div`
  display: flex;
  width: 90vw;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 1.6rem;
    color: var(--primary);
  }
`;

export const Content = styled.div`
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 800px) {
    max-width: 85vw;
  }
  .title {
    text-align: center;
    font-weight: 800;
    font-size: 7.4rem;
    color: var(--primary);
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 6rem;
    color: var(--secondary);
  }
  h4 {
    margin: 5vh;
    width: 370px;
    font-size: 2rem;
    color: var(--gray);
    text-align: center;
    line-height: 2.4rem;
    font-weight: 400;
    @media (max-width: 800px) {
      width: 80%;
    }
  }
`;

export const ButtonAccess = styled(ButtonBase)`
  && {
    border: none;
    background-color: var(--yellow);
    border-radius: 999px;
    height: 70px;
    width: 400px;
    color: var(--black);
    font-size: 2.4rem;
    font-weight: 200;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    padding: 0 20px 0 50px;
    justify-content: space-between;
    :hover {
      background-color: var(--yellow-hover);
    }

    @media (max-width: 800px) {
      height: 55px;
      width: 280px;
      font-size: 2rem;
      padding: 0 10px 0 40px;
    }
  }
`;
export const IconButtonAccess = styled.div`
  background-color: var(--white);
  width: 50px;
  height: 50px;
  border-radius: 999px;
  align-items: center;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    width: 40px;
    height: 40px;
  }
`;

export const DownloadHistory = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const DownloadHistoryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 400px;
  color: #555;
  font-size: 2.4rem;
  font-weight: 200;
  transition: 0.3s;
  padding: 0 20px 0 00px;

  @media (max-width: 800px) {
    width: 280px;
    padding: 0 10px 0 0px;
  }
  h1 {
    width: 80%;
    text-align: left;
    color: var(--secondary);
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    @media (max-width: 800px) {
      text-align: center;
    }
  }
  h3 {
    width: 80%;
    color: var(--gray);
    font-size: 2rem;
    font-weight: normal;
    line-height: 2.6rem;

    @media (max-width: 800px) {
      text-align: center;
    }
  }
`;
export const IconArrow = styled(KeyboardArrowRight)`
  && {
    font-size: 30px;
    color: var(--gray);
  }
`;
export const ButtonExplore = styled(ButtonBase)`
  && {
    border: none;
    background-color: var(--primary);
    border-radius: 10px;
    padding: 14px 32px 14px 32px;
    color: var(--white);
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;

    @media (max-width: 800px) {
      padding: 16px 40px 16px 40px;
      font-size: 2rem;
    }
    :hover {
      background-color: var(--secondary);
    }
  }
`;
export const Logo = styled.img``;
export const ServiceExempleImage = styled.img``;
export const Footer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    height: auto;
    color: var(--gray);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
  }
  a {
    color: var(--primary);
    cursor: pointer;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;

  column-gap: 8px;

  justify-content: space-around;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    row-gap: 8px;
    align-items: center;
  }
`;
