import styled from "styled-components";

export const ContentFirstLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-top: 30px;
  }
  > span {
    text-align: center;
    width: 90%;
    color: var(--gray);
    font-size: 1.6rem;
    margin: 1px 0px;
  }
  span + span + span {
    margin-bottom: 30px;
  }
`;
