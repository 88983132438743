import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBl20SfXEZpvpVZg11rfX3Cti_M33EAH7Y",
  authDomain: "souapoiador.firebaseapp.com",
  databaseURL: "https://souapoiador.firebaseio.com",
  projectId: "souapoiador",
  storageBucket: "souapoiador.appspot.com",
  messagingSenderId: "79348477956",
  appId: "1:79348477956:web:b881c6899fc59580eb8551",
  measurementId: "G-XTH8HGWE7D",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
