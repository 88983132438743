import React from "react";
import { Container, Content, LoadingIcon } from "./styles";
export default function Loading() {
  return (
    <Container>
      <Content>
        <div className="box1">
          <p>Sou</p>
        </div>
        <div className="box2">
          <div></div>
          <p>Apoiador/</p>
        </div>
        <h2>Carregando...</h2>
      </Content>
    </Container>
  );
}

export function LoadingComponent() {
  return <LoadingIcon type="spin" color="#178fd6" width={50} height={50} />;
}
