import styled, { css } from "styled-components";
import GetAppIcon from "@material-ui/icons/GetApp";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  grid-row-gap: 20px;
  align-items: center;
  display: grid;
  grid-template-rows: 50px auto 80px;
  background-color: #f9f7f9;
  @media (max-width: 800px) {
    grid-template-rows: 60px auto 80px;
  }
`;

export const Content = styled.div`
  width: 60vw;
  padding-top: 50px;
  min-height: 70vh;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media (max-width: 800px) {
    width: 100vw;
    align-items: center;
    justify-content: center;
  }
  > h1 {
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    color: var(--primary);
    &.error {
      color: var(--error);
    }
  }
  > h3 {
    display: block;

    font-size: 1.8rem;
    color: var(--gray);
    line-height: 2.3rem;
    font-weight: normal;
  }
`;

export const Header = styled.div`
  top: 0;
  z-index: 999;
  height: 50px;
  display: flex;
  width: 100%;
  padding: 0 10vw;
  align-items: center;
  background-color: var(--primary);
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  @media (max-width: 800px) {
    height: 60px;
  }
`;
export const Logo = styled.img`
  width: 80%;
  border-radius: 5px;
`;

export const Footer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    height: auto;
    color: var(--gray);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
  }
  a {
    color: var(--primary);
    cursor: pointer;
  }
`;

export const Box = styled.div`
  border-radius: 20px;
  padding: 20px;
  ${(props) => {
    switch (props.type) {
      case "total":
        return css`
          grid-area: total;
          background-color: var(--primary);
        `;
      case "profile":
        return css`
          grid-area: profile;
          background-color: var(--secondary);
        `;
      case "portrait":
        return css`
          grid-area: portrait;
          background-color: var(--yellow);
        `;
      case "landscape":
        return css`
          grid-area: landscape;
          background-color: var(--black);
        `;
      default:
        return null;
    }
  }};

  ${(props) =>
    props.type === "total"
      ? css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > h1.title {
            width: 100%;
            text-align: left;
            padding: 0 20px;
            color: var(--white);
            font-weight: bold;
            font-size: 4.4rem;
          }
          > h1.value {
            text-align: right;
            font-size: 6.4rem;
          }
        `
      : css`
          padding: 10px 15px;
          > h1.title {
            width: 100%;
            color: var(--white);
            font-weight: bold;
            font-size: 1.8rem;
          }
          > h1.value {
            text-align: center;
            font-size: 6.8rem;
            margin: 10px 0;
          }
        `}

  > h1.value {
    color: var(--white);
    font-weight: bold;
  }
  > h1.percentage {
    width: 100%;
    color: var(--white);
    font-weight: normal;
    display: block;
    text-align: center;
    font-size: 2.4rem;
  }
`;

export const Boxes = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  margin-top: 10px;
  grid-grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.8fr 10px 1fr;
  grid-template-areas:
    "total total total"
    "subTitle subTitle subTitle"
    "profile portrait landscape";

  @media (max-width: 800px) {
    padding: 0 20px;
    grid-grid-template-columns: 1fr;
    grid-template-rows: 0.8fr 10px 1fr;
    grid-template-areas:
      "total total total"
      "subTitle subTitle subTitle"
      "profile profile profile"
      "portrait portrait portrait"
      "landscape landscape landscape";
  }
`;

export const DownloadIcon = styled(GetAppIcon)`
  && {
    background-color: var(--white);
    color: var(--primary);
    border-radius: 99px;
    padding: 10px;
    font-size: 4.8rem;
  }
`;

export const SubTitle = styled.div`
  grid-area: subTitle;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  > h1 {
    text-align: left;
    font-size: 1.4rem;
    color: var(--gray);
    font-weight: normal;
    margin-right: 10px;
  }
  div.line {
    display: flex;
    height: 1px;
    width: 100%;
    background-color: #00000040;
  }
`;
