import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Footer,
  Header,
  BackgroundTop,
  Logo,
  Content,
  AddIcon,
  Champaigns,
  ButtonBox,
  LinkView,
  IconLogout,
  ContentFirstCampaign,
  LeftColumn,
  RightColumn,
  OptionsPrimaryChampaign,
  ButtonEdit,
  IconEdit,
  ButtonAnalytics,
  IconAnalytics,
  Status,
  ProfileAccount,
  CampaignName,
  ButtonUpgradePro,
  ButtonPreview,
  FooterPopUp,
  IconPreview,
  Plan,
} from "./styles";
import { Link, useHistory } from "react-router-dom";
import logoSouApoiadorHeader from "../../assets/images/logoSouApoiador2.svg";
import logoSouApoiador from "../../assets/images/logoSouApoiador.svg";
import { auth } from "../../services/auth";
import PopUp from "../../components/PopUp";
import UserSettings from "../../components/UserSettings";
import {
  getCampaignsByAuthor,
  getCampaignByName,
} from "../../services/firestore";
import firstCampaign from "../../assets/images/firstCampaign.svg";
import Button from "../../components/Button";
import Loading from "../../components/Loading";

export default function Dashboard() {
  const [campaigns, setCampaigns] = useState(null);
  const [popUpPro, setPopUpPro] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    const uid = auth.currentUser.uid;
    getCampaignsByAuthor(uid)
      .then((campaigns) => {
        setCampaigns(campaigns);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const PlanProPopUp = () => {
    return (
      <PopUp onClose={() => setPopUpPro(false)} title="Campanha PRO">
        <span>
          Você será redirecionado ao nosso WhatsApp para conhecer nossas formas
          de pagamento.
        </span>
        <FooterPopUp>
          <Button
            primary="true"
            onClick={() => {
              window.$openpix.push([
                "pix",
                {
                  value: 29000,
                  correlationID: Math.random().toString(16).slice(2),
                  description: "Um mes de campanha PRO",
                  additionalInfo: [
                    {
                      key: "campaignName",
                      value: popUpPro,
                    },
                  ],
                },
              ]);
              const unsubscribe = window.$openpix.addEventListener((e) => {
                if (e.type === "PAYMENT_STATUS") {
                  if (e.data.status === "COMPLETED") {
                    setTimeout(() => {
                      history.push(`/account/${popUpPro}/editor`);
                    }, 2500);
                    unsubscribe();
                  }
                }
              });
            }}
          >
            Realizar Pagamento
          </Button>
        </FooterPopUp>
      </PopUp>
    );
  };

  const FirstCampaign = () => {
    return (
      <ContentFirstCampaign>
        <LeftColumn>
          <h1>Bem-vindo ao Souapoiador</h1>
          <h3>
            Ferramenta para selagem de fotos de sua campanha! Crie e
            compartilhe!
          </h3>
          <OptionsPrimaryChampaign>
            <Link to="/account/newcampaign">
              <Button primary>Crie uma campanha</Button>
            </Link>
            <Link to="/souapoiador">
              <a>Ver demonstração</a>
            </Link>
          </OptionsPrimaryChampaign>
        </LeftColumn>
        <RightColumn>
          <img src={firstCampaign} />
        </RightColumn>
      </ContentFirstCampaign>
    );
  };

  return (
    <>
      {loading && <Loading />}
      {campaigns != null && (
        <Container>
          <Header>
            <Link to="/">
              <Logo src={logoSouApoiadorHeader} />
            </Link>

            <UserSettings />
          </Header>
          {campaigns?.length > 0 ? (
            <>
              <BackgroundTop />
              <Content>
                {popUpPro && <PlanProPopUp />}
                <h1>Meu Painel</h1>
                <Champaigns>
                  <ButtonBox status={true}>
                    <Link to="/account/newcampaign">
                      <LinkView />
                    </Link>
                    <AddIcon />
                    <span className="newChampaign">Nova Campanha</span>
                  </ButtonBox>

                  {campaigns?.map((campaign, index) => (
                    <ButtonBox key={index} status={campaign.published}>
                      {campaign.logo ? (
                        <Logo src={campaign.logo} />
                      ) : (
                        <CampaignName>/{campaign.name}</CampaignName>
                      )}

                      {/* "{campaign.plan === "pro" && (
                        <Plan className="pro">
                          <h1>PRO</h1>
                        </Plan>
                      )}" */}

                      <ButtonEdit
                        onClick={() =>
                          history.push(`/account/${campaign.name}/editor`)
                        }
                      >
                        <IconEdit />
                      </ButtonEdit>

                      <ButtonAnalytics
                        onClick={() =>
                          history.push(
                            "/account/" + campaign.name + "/analytics"
                          )
                        }
                      >
                        <IconAnalytics />
                      </ButtonAnalytics>
                      <ButtonPreview
                        onClick={() =>
                          window.open("/" + campaign.name, "_blank")
                        }
                      >
                        <IconPreview />
                      </ButtonPreview>
                      {campaign.plan === "free" && (
                        <ButtonUpgradePro
                          onClick={() => setPopUpPro(campaign.name)}
                        >
                          Assinar PRO
                        </ButtonUpgradePro>
                      )}
                    </ButtonBox>
                  ))}
                </Champaigns>
              </Content>
            </>
          ) : (
            <FirstCampaign />
          )}
          <Footer>
            <h1>
              ©2022 Todos os direitos reservados - Kera | Entre em{" "}
              <a
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=5599985010495"
                  )
                }
              >
                Contato
              </a>
            </h1>
          </Footer>
        </Container>
      )}
    </>
  );
}
