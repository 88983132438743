import React, { useEffect } from "react";
import {
  Content,
  Editor,
  Preview,
  Options,
  InputFile,
  TextButton,
  ContentPopUpDownload,
} from "./styles";
import Button from "../Button";
import freeWaterMark from "../../assets/images/logoSouApoiador2.svg";
import { useParams } from "react-router-dom";

import download from "../../assets/anim/download.json";
import { Camera, ExternalLink, Download, RefreshCcw } from "react-feather";
import { useGesture } from "@use-gesture/react";

import PopUp from "../../components/PopUp";
import { useState } from "react";
import {
  renderImage,
  resizeElement,
  saveBlob,
  shareBlob,
  dropPhoto,
  moveElement,
} from "./utils";

export default function EditorPhoto({ selectedLayout, resetAll, isFree }) {
  const { name } = useParams();
  const containerRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const pikedImgRef = React.useRef(null);
  const boxRef = React.useRef(null);
  const layoutImgRef = React.useRef(null);
  const [popUpDownload, setPopUpDownload] = React.useState(false);
  const [picScale, setPicScale] = React.useState(100);
  const [lastPinchDistance, setLastPinchDistance] = React.useState(1);

  useGesture(
    {
      onDrag: ({ down, pinching, cancel, delta: [x, y] }) => {
        if (pinching) return cancel();
        if (down) {
          boxRef.current.style.cursor = "grabbing";
        } else {
          boxRef.current.style.cursor = "";
        }
        moveElement(pikedImgRef.current, x, y);
      },
      onWheel: ({ event, _direction: [_, y] }) => {
        event.preventDefault();
        const scale = parseInt(picScale) - y * 10;
        if (scale < 50 || scale > 200) return;
        resizeElement(pikedImgRef.current, scale / 100);
        setPicScale(scale);
      },
      onPinch: ({ first, movement: [x] }) => {
        if (first) return setLastPinchDistance(x);
        const scale = parseInt(picScale) - (lastPinchDistance - x) * 100;
        if (scale < 50 || scale > 200) return;
        setLastPinchDistance(x);
        resizeElement(pikedImgRef.current, scale / 100);
        setPicScale(scale);
      },
    },
    {
      target: boxRef,
      wheel: {
        eventOptions: { passive: false },
      },
    }
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: download,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const PopUpDownload = () => {
    return (
      <PopUp onClose={() => setPopUpDownload(false)}>
        <ContentPopUpDownload>
          <span style={{ textAlign: "center", fontSize: "1.6rem" }}>
            Seu download iniciará em alguns segundos.
          </span>
          <span style={{ fontSize: "1rem", color: "red", textAlign: "center" }}>
            Caso ocorra problemas no download, acesse o link desta página em
            outro navegador.
          </span>
          <Button
            colorRelative={true}
            style={{ marginTop: 10, alignSelf: "center" }}
            onClick={() => setPopUpDownload(false)}
          >
            Confirmar
          </Button>
        </ContentPopUpDownload>
      </PopUp>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!selectedImage && selectedLayout) {
      const layoutImg = layoutImgRef.current;
      const box = boxRef.current;
      layoutImg.onload = function () {
        const boxRatio = box.clientWidth / box.clientHeight;
        const pikedLayoutRatio =
          layoutImg.naturalWidth / layoutImg.naturalHeight;
        console.log(box.naturalWidth, box.naturalHeight);
        if (boxRatio > pikedLayoutRatio) {
          box.style.width = box.clientHeight * pikedLayoutRatio + "px";
        } else {
          box.style.height = box.clientWidth / pikedLayoutRatio + "px";
        }
      };
      layoutImg.src = selectedLayout;
    }
    if (selectedImage && selectedLayout) {
      const box = boxRef.current;
      const pikedImg = pikedImgRef.current;
      const layoutImg = layoutImgRef.current;
      layoutImg.onload = function () {
        const boxRatio = box.clientWidth / box.clientHeight;
        const pikedLayoutRatio =
          layoutImg.naturalWidth / layoutImg.naturalHeight;
        console.log(box.naturalWidth, box.naturalHeight);
        if (boxRatio > pikedLayoutRatio) {
          box.style.width = box.clientHeight * pikedLayoutRatio + "px";
        } else {
          box.style.height = box.clientWidth / pikedLayoutRatio + "px";
        }
        pikedImg.src = selectedImage;
      };
      pikedImg.onload = function () {
        pikedImg.style.transform = "";
        const imageRatio = pikedImg.naturalWidth / pikedImg.naturalHeight;
        const boxRatio = box.clientWidth / box.clientHeight;
        if (boxRatio > imageRatio) {
          pikedImg.style.width = "100%";
          pikedImg.style.height = "auto";
        } else {
          pikedImg.style.height = "100%";
          pikedImg.style.width = "auto";
        }
      };
      layoutImg.src = selectedLayout;
    }
  }, [selectedImage, selectedLayout]);

  return (
    <Content>
      {popUpDownload && <PopUpDownload />}
      {!selectedImage && <h1 className="title">Selecione sua Foto</h1>}

      <Editor ref={containerRef}>
        {selectedImage && (
          <TextButton style={{ marginBottom: 8 }}>
            <RefreshCcw size={20} style={{ marginRight: 10 }} />
            Alterar Foto
            <InputFile
              type={"file"}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(event) => {
                dropPhoto(event, setSelectedImage);
              }}
            />
          </TextButton>
        )}
        <Preview ref={boxRef}>
          <img crossOrigin="anonymous" className="pikedImg" ref={pikedImgRef} />
          <img
            crossOrigin="anonymous"
            className="layoutImg"
            ref={layoutImgRef}
          />
          {/* {isFree && <img className="freeWaterMark" src={freeWaterMark} />} */}
        </Preview>
        <Options>
          {selectedImage ? (
            <>
              <span>Arraste sua foto para posiciona-la corretamente.</span>
              {navigator.canShare && (
                <Button
                  style={{ width: "100%", marginBottom: 8 }}
                  primary={true}
                  onClick={async () => {
                    const blob = await renderImage(
                      pikedImgRef.current,
                      layoutImgRef.current,
                      isFree
                    );
                    shareBlob(blob, name);
                  }}
                >
                  <ExternalLink size={20} style={{ marginRight: 10 }} />
                  Compartilhar
                </Button>
              )}
              <Button
                style={{ width: "100%" }}
                colorRelative={true}
                onClick={async () => {
                  const blob = await renderImage(
                    pikedImgRef.current,
                    layoutImgRef.current,
                    isFree
                  );
                  saveBlob(blob, name);
                  setPopUpDownload(true);
                }}
              >
                <Download size={20} style={{ marginRight: 10 }} />
                Download
              </Button>
            </>
          ) : (
            <Button colorRelative={true} style={{ width: "100%" }}>
              <Camera size={20} style={{ marginRight: 10 }} />
              <InputFile
                type={"file"}
                accept="image/png, image/jpeg, image/jpg"
                onChange={(event) => {
                  dropPhoto(event, setSelectedImage);
                }}
              />
              Escolha sua Foto
            </Button>
          )}
        </Options>
      </Editor>
    </Content>
  );
}
