import styled from "styled-components";
import { Slider } from "@material-ui/core";

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 800px) {
    max-width: 100vw;
  }
  .title {
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    color: var(--primaryChampaign);
  }

  h4 {
    margin-top: 1rem;
    width: 370px;
    font-size: 2rem;
    color: var(--gray);
    text-align: center;
    line-height: 2.4rem;
    font-weight: normal;
    @media (max-width: 800px) {
      width: 80%;
    }
  }
`;

export const ZoomContainer = styled.div`
  border-radius: 999px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  color: var(--secondaryChampaign);
  font-size: 2.4rem;
  margin: 10px;
  font-weight: bold;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Editor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 40px;
  height: 100%;
`;

export const Preview = styled.div`
  width: 50%;
  height: 400px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: 0.3s;
  margin: auto;
  justify-content: center;
  position: relative;
  margin: 20px 0;
  touch-action: none;
  img {
    pointer-events: none;
  }
  img.pikedImg {
    position: absolute;
  }
  img.layoutImg {
    cursor: move;
    position: absolute;
    height: 100%;
  }
  img.freeWaterMark {
    z-index: 10;
    opacity: 0.6;
    width: 70%;
  }
  @media (max-width: 800px) {
    width: 80vw;
    height: 50vh;
  }
`;

export const Options = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 100px;
  > h1 {
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
    color: var(--secondaryChampaign);
  }
  > span {
    width: 250px;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 20px;
    text-align: center;
    color: var(--gray);
    margin-bottom: 10px;
  }
  > a {
    font-style: bold;
    font-size: 1.6rem;
    text-align: center;
    color: var(--primaryChampaign);
    margin-top: 15px;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    width: 80vw;
    padding: 0;
  }
`;

export const SliderZoom = styled(Slider)`
  && {
    margin-bottom: 20px;
    width: 70%;
    .MuiSlider-thumb {
      color: #006eff;
      width: 20px;
      height: 20px;
    }
    .MuiSlider-rail {
      height: 10px;

      border-radius: 20px;
      color: #c4c4c4;
    }
    .MuiSlider-track {
      height: 10px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      color: #c4c4c4;
    }
    @media (max-width: 800px) {
      width: 80%;
    }
  }
`;

export const InputFile = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  color: transparent;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`;

export const TextButton = styled.a`
  color: var(--secondaryChampaign);
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  position: relative;
  font-weight: bold;
  cursor: pointer;
`;

export const ContentPopUpDownload = styled.div`
  width: 80;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
