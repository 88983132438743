import React, { useEffect } from "react";
import { useState } from "react";
import {
  Content,
  SelectLayout,
  SelectLayoutsContainer,
  LayoutExemple as LayoutExample,
  ButtonSelectLayout,
  AvatarIcon,
  StoriesIcon,
  LandscapeIcon,
  ArrowIcon,
  ButtonSelectLayoutMobile,
  AddLayout,
  FeedIcon,
} from "./styles";
import { Plus } from "react-feather";
export default function SelectLayoutType({
  enableTypes,
  currentLayoutTypeSelected,
  isEditor,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  return (
    <Content>
      {enableTypes.length > 1 ? (
        <h1 className="title">Selecione o tipo de moldura</h1>
      ) : (
        <h1 className="title">Nenhuma moldura adicionada nesta campanha</h1>
      )}

      <SelectLayoutsContainer>
        {(enableTypes.includes("squareLayouts") || isEditor) && (
          <SelectLayout
            onClick={() =>
              width < 800 &&
              currentLayoutTypeSelected({
                title: "Perfil",
                type: "squareLayouts",
              })
            }
          >
            <LayoutExample>
              <AvatarIcon />
            </LayoutExample>
            <h1>Perfil</h1>
            <h3 className="description">
              Estilo de selagem para fotos de perfis convencionais
            </h3>
            <ButtonSelectLayout
              onClick={() =>
                currentLayoutTypeSelected({
                  title: "Perfil",
                  type: "squareLayouts",
                })
              }
            >
              Selecionar
            </ButtonSelectLayout>
            <ButtonSelectLayoutMobile>
              <ArrowIcon />
            </ButtonSelectLayoutMobile>
            {isEditor && !enableTypes.includes("squareLayouts") && (
              <AddLayout
                onClick={() =>
                  currentLayoutTypeSelected({
                    title: "Perfil",
                    type: "squareLayouts",
                  })
                }
              >
                <Plus size={40} />
                <span>Adicionar Layouts de Perfil</span>
              </AddLayout>
            )}
          </SelectLayout>
        )}
        {(enableTypes.includes("portraitLayouts") || isEditor) && (
          <SelectLayout
            onClick={() =>
              width < 800 &&
              currentLayoutTypeSelected({
                title: "Stories",
                type: "portraitLayouts",
              })
            }
          >
            <LayoutExample>
              <StoriesIcon />
            </LayoutExample>

            <h1>Stories</h1>
            <h3 className="description">
              Estilo de selagem para fotos de stories
            </h3>
            <ButtonSelectLayout
              onClick={() =>
                currentLayoutTypeSelected({
                  title: "Stories",
                  type: "portraitLayouts",
                })
              }
            >
              Selecionar
            </ButtonSelectLayout>
            <ButtonSelectLayoutMobile>
              <FeedIcon />
            </ButtonSelectLayoutMobile>
            {isEditor && !enableTypes.includes("portraitLayouts") && (
              <AddLayout
                onClick={() =>
                  currentLayoutTypeSelected({
                    title: "Stories",
                    type: "portraitLayouts",
                  })
                }
              >
                <Plus size={40} />
                <span>Adicionar Layouts de Stories</span>
              </AddLayout>
            )}
          </SelectLayout>
        )}
        {(enableTypes.includes("verticalFeed") || isEditor) && (
          <SelectLayout
            onClick={() =>
              width < 800 &&
              currentLayoutTypeSelected({
                title: "Feed Vertical",
                type: "verticalFeed",
              })
            }
          >
            <LayoutExample>
              <FeedIcon />
            </LayoutExample>
            <h1>Feed Vertical</h1>
            <h3 className="description">
              Estilo de selagem para fotos de feed no modo vertical
            </h3>
            <ButtonSelectLayout
              onClick={() =>
                currentLayoutTypeSelected({
                  title: "Feed Vertical",
                  type: "verticalFeed",
                })
              }
            >
              Selecionar
            </ButtonSelectLayout>
            <ButtonSelectLayoutMobile>
              <FeedIcon />
            </ButtonSelectLayoutMobile>
            {isEditor && !enableTypes.includes("verticalFeed") && (
              <AddLayout
                onClick={() =>
                  currentLayoutTypeSelected({
                    title: "Feed Vertical",
                    type: "verticalFeed",
                  })
                }
              >
                <Plus size={40} />
                <span>Adicionar Layouts de Feed Vertical</span>
              </AddLayout>
            )}
          </SelectLayout>
        )}
        {(enableTypes.includes("landscapeLayouts") || isEditor) && (
          <SelectLayout
            onClick={() =>
              width < 800 &&
              currentLayoutTypeSelected({
                title: "Feed Horizontal",
                type: "landscapeLayouts",
              })
            }
          >
            <LayoutExample>
              <LandscapeIcon />
            </LayoutExample>
            <h1>Feed Horizontal</h1>
            <h3 className="description">
              Estilo de selagem para fotos de feed modo horizontal
            </h3>
            <ButtonSelectLayout
              onClick={() =>
                currentLayoutTypeSelected({
                  title: "Feed Horizontal",
                  type: "landscapeLayouts",
                })
              }
            >
              Selecionar
            </ButtonSelectLayout>
            <ButtonSelectLayoutMobile>
              <ArrowIcon />
            </ButtonSelectLayoutMobile>
            {isEditor && !enableTypes.includes("landscapeLayouts") && (
              <AddLayout
                onClick={() =>
                  currentLayoutTypeSelected({
                    title: "Feed Horizontal",
                    type: "landscapeLayouts",
                  })
                }
              >
                <Plus size={40} />
                <span>Adicionar Layouts de Feed Horizontal</span>
              </AddLayout>
            )}
          </SelectLayout>
        )}
      </SelectLayoutsContainer>
    </Content>
  );
}
