import styled, { css } from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";

export const ButtonComponent = styled(ButtonBase)`
  && {
    width: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    position: relative;
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--white);
    background-color: var(--yellow);
    &:hover {
      transform: scale(1.03);
      transition: 0.3s;
    }
    ${(props) =>
      props.fullWidth &&
      css`
        width: 100%;
      `}

    ${(props) =>
      props.fullWidthMobile &&
      css`
        width: auto;
        @media (max-width: 800px) {
          width: 100%;
        }
      `}
    ${(props) =>
      props.character &&
      css`
        background-color: transparent;
        padding: 12px;
        color: var(--primaryChampaign);
      `}
    ${(props) =>
      props.colorRelative &&
      css`
        background-color: var(--secondaryChampaign);
      `}
    ${(props) =>
      props.type === "warning" &&
      css`
        background-color: var(--error);
      `}

    ${(props) =>
      props.type === "outline" &&
      css`
        background-color: transparent;
        outline: 2px solid var(--gray10);
        outline-offset: -2px;
        color: var(--gray);
      `}
      ${(props) =>
      props.primary &&
      css`
        background-color: var(--primaryChampaign);
      `}
      ${(props) =>
      props.danger &&
      css`
        background-color: var(--error);
      `}

      ${(props) =>
      props.disabled &&
      css`
        background-color: var(--gray20);
      `}
  }
`;
