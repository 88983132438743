import React, { useState, useCallback, useRef } from "react";
import {
  Container,
  Footer,
  Header,
  Logo,
  Content,
  Form,
  PlansContainer,
  VerifyCampaignNameStatus,
} from "./styles";

import Button from "../../components/Button";
import { Link, useHistory } from "react-router-dom";
import logoSouApoiador from "../../assets/images/logoSouApoiador.svg";
import { createCampaign, verifyCampaignName } from "../../services/firestore";
import { auth } from "../../services/auth";
import { createAlert } from "../../components/Alert";
import UserSettings from "../../components/UserSettings";
import Plan from "../../components/Plan";
export default function NewCampaign() {
  const [campaignName, setCampaignName] = useState("");
  const [campaignNameTemp, setCampaignNameTemp] = useState("");
  const [campaignNameIsBeingUsed, setCampaignNameIsBeingUsed] = useState(null);
  const [nameHasAvailable, setNameHasAvailable] = useState(false);
  const history = useHistory();

  const validateCampaignName = useCallback((value) => {
    setNameHasAvailable(false);
    setCampaignName("");
    if (value.match(/^[0-9a-z]+$/) && value.length <= 20 && value != "") {
      setCampaignName(value);
      verifyCampaignName(value).then((res) => {
        if (res) {
          setNameHasAvailable(res);
          setCampaignNameIsBeingUsed(false);
        } else {
          setCampaignNameIsBeingUsed(true);
        }
      });
    } else {
      createAlert(
        "error",
        "O Link deve conter no máximo 20 caracteres e nao possuir espaços"
      );
    }
  }, []);

  return (
    <>
      <Container>
        <Header>
          <Link to="/">
            <Logo src={logoSouApoiador} />
          </Link>
          <UserSettings />
        </Header>
        <Content>
          <h1>Nova Campanha</h1>
          <Form>
            <label>Link</label>
            <div className="inputContainer">
              <input
                placeholder="Nome de sua campanha"
                type="text"
                required
                value={campaignNameTemp}
                onChange={(event) =>
                  setCampaignNameTemp(event.target.value.toLowerCase())
                }
              />
              <span>souapoiador.com.br/{campaignNameTemp}</span>
              <Button
                fullWidthMobile={true}
                primary={true}
                onClick={() => validateCampaignName(campaignNameTemp)}
              >
                Verificar link
              </Button>
            </div>

            <span>souapoiador.com.br/{campaignNameTemp}</span>
          </Form>
          {campaignName && (
            <>
              {campaignNameIsBeingUsed != null && (
                <VerifyCampaignNameStatus>
                  <h1>
                    <span>O link</span> souapoiador.com.br/{campaignName}
                  </h1>

                  {!campaignNameIsBeingUsed ? (
                    <h1 className="green">Está disponível!</h1>
                  ) : (
                    <h1 className="red">Já está sendo usado :(</h1>
                  )}
                </VerifyCampaignNameStatus>
              )}
              {nameHasAvailable && (
                <PlansContainer>
                  <Plan
                    title="Grátis"
                    description="Para o seu uso básico"
                    price="0,00"
                    colorButton="#cfcfcf"
                    textColorButton="#6c6767"
                    onClick={() =>
                      createCampaign(campaignName, "free", auth.currentUser.uid)
                        .then(() =>
                          history.push(`/account/${campaignName}/editor`)
                        )
                        .catch((err) => {
                          console.error(err);
                        })
                    }
                  >
                    <span>Contém Anúncios</span>
                    <span>Até 3 Molduras</span>
                  </Plan>
                  <Plan
                    title="Profissional"
                    description="Garanta todas nossos recursos"
                    price="29,90"
                    colorButton="#f2cb05"
                    onClick={() =>
                      createCampaign(campaignName, "free", auth.currentUser.uid)
                        .then(() => {
                          window.$openpix.push([
                            "pix",
                            {
                              value: 2990,
                              correlationID: Math.random()
                                .toString(16)
                                .slice(2),
                              description: "Um mes de campanha PRO",
                              additionalInfo: [
                                {
                                  key: "campaignName",
                                  value: campaignName,
                                },
                              ],
                            },
                          ]);
                          const unsubscribe = window.$openpix.addEventListener(
                            (e) => {
                              if (e.type === "PAYMENT_STATUS") {
                                if (e.data.status === "COMPLETED") {
                                  setTimeout(() => {
                                    history.push(
                                      `/account/${campaignName}/editor`
                                    );
                                  }, 2500);
                                  unsubscribe();
                                }
                              }
                            }
                          );
                        })
                        .catch((err) => {
                          console.error(err);
                        })
                    }
                  >
                    <span>Não Contém Anúncios</span>
                    <span>Molduras ilimitadas</span>
                    <span>Inserir logo personalizada</span>
                    <span>Inserir cores personalizadas</span>
                    <span>Estatísticas de fotos baixadas</span>
                  </Plan>
                </PlansContainer>
              )}
            </>
          )}
        </Content>
        <Footer>
          <h1>
            ©2022 Todos os direitos reservados - Kera | Entre em{" "}
            <a
              onClick={() =>
                window.open("https://api.whatsapp.com/send?phone=5599985010495")
              }
            >
              Contato
            </a>
          </h1>
        </Footer>
      </Container>
    </>
  );
}
